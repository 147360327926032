import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { httpsCallable, getFunctions } from 'firebase/functions';
import Loader from '../components/Loader';

const PostCheckout = () => {
  const { state, sessionId } = useParams();
  const navigate = useNavigate();
  const [payment, setPayment] = useState();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const getCheckoutSession = async () => {
      const successfulPayment = httpsCallable(
        getFunctions(),
        'successfulPayment'
      );
      const paymentData = await successfulPayment(sessionId);
      setPayment(paymentData.data);
      setReady(true);
    };

    if (state === 'cancelled') {
      setTimeout(() => navigate('/'), 3300, { replace: true });
    } else {
      getCheckoutSession();
    }
  }, [state, navigate, sessionId]);

  let dashboardContent;
  if (state === 'cancelled') {
    dashboardContent = (
      <>
        <Typography variant="h4" gutterBottom>
          🤷‍♂️ Changed your mind?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Redirecting you back to the homepage 🐢
        </Typography>
      </>
    );
  } else if (!ready) {
    dashboardContent = <Loader status="processing..." />;
  } else {
    dashboardContent = (
      <>
        <Typography variant="h4" gutterBottom>
          🐳 Thank you {payment.firstName}!
        </Typography>
        <Typography variant="body1" gutterBottom>
          ✅ Your payment has been received.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We've sent you a confirmation email.
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ margin: 3 }}
          onClick={() => navigate('/', { replace: true })}
        >
          Return to Synergy Diving
        </Button>
      </>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} sx={{ mt: 8, p: 3 }}>
        {dashboardContent}
      </Grid>
    </Grid>
  );
};

export default PostCheckout;
