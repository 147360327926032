import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  CardContent,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Stack
} from '@mui/material';
import { Close, VerifiedUser } from '@mui/icons-material';
import { useStripe } from '@stripe/react-stripe-js';
import { httpsCallable, getFunctions } from 'firebase/functions';
import {
  addDoc,
  doc,
  getFirestore,
  collection,
  getDoc,
  getDocs,
  Timestamp
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import Loader from '../Loader';
import GooglePlaces from '../GoogleMaps';
import { generateParagraphs } from '../../modules/utils';

const Product = ({ productId, variantId }) => {
  const stripe = useStripe();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [postalAddress, setPostalAddress] = useState();
  const [product, setProduct] = useState();
  const [member, setMember] = useState();
  const [confirmedTerms, setConfirmedTerms] = useState(false);
  const [processing, setProcessing] = useState('Initialising...');
  const [showMember, setShowMember] = useState(false);
  const [marketing, setMarketing] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      setProcessing('Loading product...');
      const productSnapshot = await getDoc(
        doc(getFirestore(), 'products', productId)
      );

      const variantSnapshot = await getDoc(
        doc(getFirestore(), 'products', productId, 'variants', variantId)
      );

      setProcessing('Loading member data...');
      const { firstName, lastName, bio } = (
        await await getDoc(
          doc(getFirestore(), 'members', productSnapshot.data().memberId)
        )
      ).data();

      setProcessing('Checking for verifications...');
      const verificationsSnapshot = await getDocs(
        collection(
          getFirestore(),
          'members',
          productSnapshot.data().memberId,
          'verifications'
        )
      );

      const verificationData = {};
      for (const verficationSnapshot of verificationsSnapshot.docs) {
        if (verficationSnapshot.data().verified) {
          verificationData[verficationSnapshot.id] = verficationSnapshot.data();
        }
      }

      const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

      setProcessing('Loading member image...');
      let memberImage;
      try {
        memberImage = await getDownloadURL(
          ref(
            getStorage(),
            `members/${
              productSnapshot.data().memberId
            }/profile_${maxImageDimension}x${maxImageDimension}.jpeg`
          )
        );
      } catch (error) {
        console.log(error.code);
        memberImage = `https://picsum.photos/${maxImageDimension}`;
      }

      let image;
      try {
        image = await getDownloadURL(
          ref(
            getStorage(),
            `products/${productSnapshot.data().memberId}/${
              productSnapshot.id
            }_${maxImageDimension}x${maxImageDimension}.jpeg`
          )
        );
      } catch (error) {
        image = 'https://picsum.photos/800';
      }

      setProduct({
        ...productSnapshot.data(),
        image,
        id: productSnapshot.id,
        variant: { ...variantSnapshot.data() }
      });
      setMember({
        image: memberImage,
        name: `${firstName} ${lastName}`,
        bio,
        verifications: verificationData
      });
      setProcessing('');
    };

    getProduct();
  }, [productId, variantId]);

  if (processing !== '') {
    return <Loader status={processing} />;
  }

  return (
    <Grid container justifyContent="center" sx={{ p: 1 }}>
      <Grid item xs={12} sx={{ p: 3 }}>
        <Typography variant="h4">Checkout</Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={{ mb: 1, p: 1 }}>
        <Card>
          <CardHeader
            avatar={
              <Avatar src={member.image} onClick={() => setShowMember(true)} />
            }
            title={`${product.title} (${product.variant.title})`}
          />
          <CardMedia
            image={product.image}
            title={product.title}
            sx={{ height: 555 }}
          />
          <CardContent>
            {product.description && generateParagraphs(product.description)}
            <Typography variant="body2" color="textSecondary">
              ${product.variant.price}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {product.memberName}
            </Typography>
          </CardContent>
        </Card>
        <Dialog
          open={showMember}
          onClose={() => setShowMember(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <AppBar color="secondary" position="absolute">
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={() => setShowMember(false)}
                aria-label="close"
                size="large"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container sx={{ mt: 8 }}>
              <Grid item xs={12} md={6}>
                <Avatar
                  src={member.image}
                  sx={{
                    height: 222,
                    width: 222,
                    mb: 2
                  }}
                  variant="rounded"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  {member.name}
                </Typography>

                {Object.keys(member.verifications).map(verificationId => (
                  <Typography
                    variant="body1"
                    key={verificationId}
                    sx={{
                      display: 'flex'
                    }}
                    gutterBottom
                  >
                    <VerifiedUser sx={{ mr: 1 }} />{' '}
                    {member.verifications[verificationId].assertion}
                  </Typography>
                ))}

                <Typography variant="body2" gutterBottom>
                  {member.bio}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
      <Grid item md={6} sx={{ p: 1 }}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={3}>
            <Typography variant="h6" gutterBottom>
              We just need a few details
            </Typography>
            <TextField
              label="First name"
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={evt => setFirstName(evt.target.value)}
            />
            <TextField
              label="Last name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={evt => setLastName(evt.target.value)}
            />
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={evt => setEmail(evt.target.value)}
            />
            <GooglePlaces
              label="Type in your shipping address"
              setValue={setPostalAddress}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={confirmedTerms}
                    onChange={() => setConfirmedTerms(!confirmedTerms)}
                  />
                }
                label="I agree to Synergy Diving's terms and conditions, and privacy policy"
              />
              <Typography variant="caption">
                <ul>
                  <li>
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a href="/privacy" target="_blank" rel="noreferrer">
                      Privacy policy
                    </a>
                  </li>
                </ul>
              </Typography>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketing}
                    onChange={() => setMarketing(!marketing)}
                  />
                }
                label="Shell yeah, I’d like to receive promotional updates from Synergy Diving"
              />
            </div>
            <Button
              variant="contained"
              disabled={
                firstName.trim() === '' ||
                lastName.trim() === '' ||
                email.trim() === '' ||
                !postalAddress ||
                !confirmedTerms
              }
              color="primary"
              onClick={async () => {
                setProcessing('Setting up payment on Synergy Diving...');
                const res = await addDoc(
                  collection(getFirestore(), 'payments'),
                  {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: email.trim(),
                    productId,
                    variantId,
                    place: {
                      id: postalAddress.place_id,
                      address: postalAddress.description
                    },
                    initiatedAt: Timestamp.now()
                  }
                );

                const createCheckoutSession = httpsCallable(
                  getFunctions(),
                  'createCheckoutSession'
                );
                const session = await createCheckoutSession({
                  product,
                  paymentId: res.id
                });

                if (marketing) {
                  const marketingAdd = httpsCallable(
                    getFunctions(),
                    'marketingAdd'
                  );
                  await marketingAdd({
                    firstName,
                    lastName,
                    email,
                    paymentId: res.id
                  });
                }

                await stripe.redirectToCheckout({
                  sessionId: session.data.id
                });
              }}
            >
              Continue to payment
            </Button>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Product;
