import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Button,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Footer from '../components/Footer';
import logo from '../images/synergy-diving-logo.png';
import backgroundImage from '../images/ray.jpeg';

const MemberHome = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Grid
        container
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12} md={9} lg={6}>
          <Paper
            style={{
              padding: xsScreen ? 22 : 33,
              marginTop: xsScreen ? 22 : 55,
              marginLeft: xsScreen ? 11 : 88,
              marginRight: xsScreen ? 11 : 88,
              marginBottom: 11
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 11,
                cursor: 'pointer'
              }}
              onClick={() => navigate('/', { replace: true })}
            >
              <img
                src={logo}
                alt="Synergy Diving logo"
                style={{ width: 44, marginRight: 11 }}
              />
              <Typography variant="h5">Synergy Diving</Typography>
            </div>
            <Typography variant="subtitle1" gutterBottom>
              For our diving instructors.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/member/signup')}
            >
              Become a member
            </Button>
            <div style={{ marginTop: 11 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/member/login')}
              >
                Login
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default MemberHome;
