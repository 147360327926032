// used in the dashboard to render an experience, and be able to edit it
import React, { useState, useEffect } from 'react';
import {
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Menu,
  Alert,
  MenuItem,
  AlertTitle,
  Link
} from '@mui/material';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { Edit, Delete, Share, MoreVert } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import ExperienceData from './ExperienceData';
import Loader from './Loader';
import {
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
  Timestamp,
  GeoPoint,
  addDoc,
  collection
} from 'firebase/firestore';
import {
  ref,
  getStorage,
  getDownloadURL,
  deleteObject,
  uploadBytes
} from 'firebase/storage';
import { httpsCallable, getFunctions } from 'firebase/functions';

import { geocode } from '../modules/maps';
import { experienceDuration } from '../modules/utils';

dayjs.extend(objectSupport);

export default function Experience({
  title,
  price,
  imageRef,
  startDate,
  endDate,
  maxParticipants,
  participants,
  description,
  type,
  place,
  prerequisites,
  experienceId,
  imageDimension,
  uid,
  nonrefundableDeposit
}) {
  const [confirm, setConfirm] = useState(false);
  const [showEditExperience, setShowEditExperience] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [showDuplicateExperience, setShowDuplicateExperience] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      let imgUrl;
      try {
        imgUrl = await getDownloadURL(imageRef);
      } catch (error) {
        imgUrl = `https://picsum.photos/${imageDimension}`;
      }
      setImageUrl(imgUrl);
    };
    loadImage();
  }, [imageDimension, imageRef]);

  if (!imageUrl) {
    return <Loader />;
  }

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          action={
            <IconButton onClick={event => setMenuAnchorEl(event.currentTarget)}>
              <MoreVert />
            </IconButton>
          }
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={() => setShowDuplicateExperience(true)}>
            Duplicate
          </MenuItem>
        </Menu>
        <CardMedia image={imageUrl} title={title} style={{ height: 222 }} />
        <CardContent>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {description}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Start date: {dayjs(startDate).format('DD MMM YYYY')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            End date: {dayjs(endDate).format('DD MMM YYYY')}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Experience length:{' '}
            {experienceDuration(dayjs(startDate), dayjs(endDate))}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Location: {place.address}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Participants ({participants.length}):{' '}
            {participants.length === 0
              ? 'none yet'
              : `${participants.join(', ')}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Max participants: {maxParticipants}
          </Typography>
          {prerequisites && (
            <>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                style={{ fontStyle: 'italic' }}
              >
                Prerequisites: {prerequisites}
              </Typography>
            </>
          )}
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Price: {`$${price}`}
            {nonrefundableDeposit && nonrefundableDeposit.required
              ? ` (nonrefundable deposit: $${nonrefundableDeposit.amount})`
              : ''}
          </Typography>
          {participants.length > 0 && (
            <Alert variant="outlined" severity="info" sx={{ mt: 3 }}>
              <AlertTitle>
                Looking to edit or delete this experience?
              </AlertTitle>
              Your experience already has participants booked for it. If you'd
              like to make any changes, please{' '}
              <Link component={RouterLink} to="/contact">
                contact us
              </Link>{' '}
              with the changes you'd like to make.
            </Alert>
          )}
        </CardContent>
        {dayjs(startDate).isAfter(dayjs()) && (
          <CardActions disableSpacing>
            <IconButton
              aria-label="share"
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${window.location.origin}/checkout/experience/${experienceId}`
                );
                setSnackbar({
                  open: true,
                  message: `"${title}" link copied to clipboard...`,
                  duration: 5500
                });
              }}
            >
              <Share />
            </IconButton>
            {participants.length === 0 && (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() => setShowEditExperience(true)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => setConfirm(true)}
                >
                  <Delete />
                </IconButton>
              </>
            )}
          </CardActions>
        )}
      </Card>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Delete the "{title}" experience?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirm(false);
              deleteObject(
                ref(
                  getStorage(),
                  `experiences/${uid}/${experienceId}_${imageDimension}x${imageDimension}.jpeg`
                )
              );
              deleteDoc(doc(getFirestore(), 'experiences', experienceId));

              setSnackbar({
                open: true,
                message: 'Deleting experience...',
                duration: 8800
              });

              return updateDoc(doc(getFirestore(), 'members', uid), {
                lastUpdated: Timestamp.now()
              });
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {showEditExperience && (
        <ExperienceData
          uid={uid}
          initialTitle={title}
          initialDescription={description}
          initialPrerequisites={prerequisites}
          initialPlace={place}
          initialPrice={price}
          initialExperienceImage={imageUrl}
          initialMaxParticipants={maxParticipants}
          initialStartDate={
            new Date(startDate.year, startDate.month, startDate.date)
          }
          initialEndDate={new Date(endDate.year, endDate.month, endDate.date)}
          initialType={type}
          initialNonrefundableDeposit={nonrefundableDeposit}
          showExperienceDialog={showEditExperience}
          dialogTitle="Edit an experience"
          onSave={async ({
            title,
            startDate,
            endDate,
            description,
            prerequisites,
            maxParticipants,
            price,
            placeId,
            experienceImage,
            type,
            nonrefundableDeposit
          }) => {
            const placeDetails = await geocode(placeId);

            await updateDoc(doc(getFirestore(), 'experiences', experienceId), {
              price: parseFloat(price),
              nonrefundableDeposit,
              description,
              prerequisites,
              place: {
                id: placeId,
                address: placeDetails.formatted_address,
                location: new GeoPoint(
                  placeDetails.geometry.location.lat(),
                  placeDetails.geometry.location.lng()
                )
              },
              'participants.max': parseInt(maxParticipants, 10),
              type,
              dates: {
                start: {
                  date: startDate.date(),
                  month: startDate.month(),
                  year: startDate.year()
                },
                end: {
                  date: endDate.date(),
                  month: endDate.month(),
                  year: endDate.year()
                }
              },
              title,
              memberId: uid
            });

            await uploadBytes(
              ref(getStorage(), `experiences/${uid}/${experienceId}.jpeg`),
              experienceImage
            );

            return await updateDoc(doc(getFirestore(), 'members', uid), {
              lastUpdated: Timestamp.now()
            });
          }}
          imageDimension={imageDimension}
          closeDialog={updated => {
            setShowEditExperience(false);
            if (updated) {
              setSnackbar({
                open: true,
                message: 'Updating experience...',
                duration: 8800
              });
            }
          }}
        />
      )}
      {showDuplicateExperience && (
        <ExperienceData
          uid={uid}
          initialTitle={title}
          initialDescription={description}
          initialPrerequisites={prerequisites}
          initialPlace={place}
          initialStartDate={new Date()}
          initialEndDate={dayjs(new Date())
            .add(dayjs(endDate).diff(startDate, 'day'), 'days')
            .toDate()}
          initialPrice={price}
          initialExperienceImage={imageUrl}
          initialMaxParticipants={maxParticipants}
          initialType={type}
          initialNonrefundableDeposit={nonrefundableDeposit}
          showExperienceDialog={showDuplicateExperience}
          dialogTitle="Duplicate an experience"
          onSave={async ({
            title,
            startDate,
            endDate,
            description,
            prerequisites,
            maxParticipants,
            price,
            placeId,
            experienceImage,
            type,
            nonrefundableDeposit
          }) => {
            const placeDetails = await geocode(placeId);

            const res = await addDoc(
              collection(getFirestore(), 'experiences'),
              {
                price: parseFloat(price),
                nonrefundableDeposit,
                description,
                prerequisites,
                place: {
                  id: placeId,
                  address: placeDetails.formatted_address,
                  location: new GeoPoint(
                    placeDetails.geometry.location.lat(),
                    placeDetails.geometry.location.lng()
                  )
                },
                participants: { max: parseInt(maxParticipants), enrolled: 0 },
                type,
                title,
                memberId: uid,
                dates: {
                  start: {
                    date: startDate.date(),
                    month: startDate.month(),
                    year: startDate.year()
                  },
                  end: {
                    date: endDate.date(),
                    month: endDate.month(),
                    year: endDate.year()
                  }
                }
              }
            );

            if (typeof experienceImage === 'string') {
              const duplicateImage = httpsCallable(
                getFunctions(),
                'duplicateImage'
              );
              await duplicateImage({
                srcFilename: imageRef.fullPath,
                destFilename: `experiences/${uid}/${res.id}_${imageDimension}x${imageDimension}.jpeg`
              });
            } else {
              await uploadBytes(
                ref(getStorage(), `experiences/${uid}/${res.id}.jpeg`),
                experienceImage
              );
            }

            return updateDoc(doc(getFirestore(), 'members', uid), {
              lastUpdated: Timestamp.now()
            });
          }}
          imageDimension={imageDimension}
          closeDialog={updated => {
            setMenuAnchorEl(null);
            setShowDuplicateExperience(false);
            if (updated) {
              setSnackbar({
                open: true,
                message: 'Duplicating experience...',
                duration: 8800
              });
            }
          }}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
      />
    </>
  );
}
