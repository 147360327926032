import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  AlertTitle,
  Typography,
  Button,
  TextField,
  Backdrop,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tab,
  Snackbar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  IconButton,
  Paper,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
  CircularProgress,
  Alert,
  ImageList,
  InputAdornment,
  LinearProgress,
  Stack,
  Container
} from '@mui/material';
import {
  VerifiedUser,
  Delete,
  Add,
  Warning,
  CloudUpload,
  Edit,
  Download
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { httpsCallable, getFunctions } from 'firebase/functions';
import {
  Timestamp,
  getDocs,
  collection,
  getFirestore,
  addDoc,
  GeoPoint,
  updateDoc,
  onSnapshot,
  doc,
  where,
  deleteDoc,
  query,
  getDoc
} from 'firebase/firestore';
import { getAuth, updateEmail } from 'firebase/auth';
import {
  uploadBytes,
  ref,
  deleteObject,
  getStorage,
  getDownloadURL,
  listAll
} from 'firebase/storage';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Experience from './Experience';
import ImageEditor from './ImageEditor';
import Product from './Product';
import ExperienceData from './ExperienceData';
import ProductData from './ProductData';
import Header from './HeaderBar';
import Footer from './Footer';
import Loader from './Loader';
import { geocode } from '../modules/maps';
import { getSupportedStripeCountries } from '../modules/stripe';
import backgroundImage from '../images/underwater.jpeg';
import { isProfileReady } from '../modules/utils';

dayjs.extend(objectSupport);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumUp = useMediaQuery(theme.breakpoints.up('md'));

  const [userData, setUserData] = useState();
  const imageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

  // profile
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bio, setBio] = useState('');
  const [updateEmailAddress, setUpdateEmailAddress] = useState(false);
  const [editProfileImage, setEditProfileImage] = useState(false);
  const [newEmail, setNewEmail] = useState();

  // experiences
  const [experiences, setExperiences] = useState();

  // products
  const [products, setProducts] = useState();

  // featured images
  const [featuredImages, setFeaturedImages] = useState([]);

  // verifications
  const [verifications, setVerifications] = useState([]);
  const [addVerification, setAddVerification] = useState(false);
  const [newVerification, setNewVerification] = useState('');

  // referrals
  const [referralsUsed, setReferralsUsed] = useState([]);
  const [referredBy, setReferredBy] = useState();

  // stripe account data
  const [countryCode, setCountryCode] = useState(null);
  const [countryCodes, setCountryCodes] = useState('');
  const [balance, setBalance] = useState();
  const [stripeAccount, setStripeAccount] = useState();

  // UI states
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showExperienceDialog, setShowExperienceDialog] = useState(false);
  const [showProductDialog, setShowProductDialog] = useState(false);
  const [tab, setTab] = useState('1');

  useEffect(() => {
    if (!getAuth().currentUser) {
      navigate('/member/login', { replace: true });
    } else {
      updateDoc(doc(getFirestore(), 'members', getAuth().currentUser.uid), {
        'timestamps.lastLogin': Timestamp.now()
      });
    }
  }, [navigate]);

  // fetch profile data for member
  useEffect(() => {
    const getProfileData = async () => {
      setLoadingMessage('Loading profile data...');

      onSnapshot(
        doc(getFirestore(), 'members', getAuth().currentUser.uid),
        async loggedInUser => {
          setUserData(loggedInUser.data());
          setFirstName(loggedInUser.data().firstName);
          setLastName(loggedInUser.data().lastName);
          setBio(loggedInUser.data().bio);
          setShowBackdrop(false);
        }
      );
    };

    if (getAuth().currentUser) {
      getProfileData();
    }
  }, []);

  useEffect(() => {
    const getVerifications = async () => {
      setLoadingMessage('Loading verifications...');

      onSnapshot(
        collection(
          getFirestore(),
          'members',
          getAuth().currentUser.uid,
          'verifications'
        ),
        verificationsSnapshot => {
          const verificationData = {};
          verificationsSnapshot.docs.map(async verficationSnapshot => {
            const downloadUrl = await getDownloadURL(
              ref(
                getStorage(),
                `verifications/${getAuth().currentUser.uid}/${
                  verficationSnapshot.id
                }.${verficationSnapshot.data().fileType}`
              )
            );
            verificationData[verficationSnapshot.id] = {
              ...verficationSnapshot.data(),
              downloadUrl
            };
          });
          setVerifications(verificationData);
        }
      );
    };

    if (getAuth().currentUser) {
      getVerifications();
    }
  }, []);

  useEffect(() => {
    const getReferrals = async () => {
      setLoadingMessage('Loading referrals...');

      onSnapshot(
        collection(
          getFirestore(),
          'members',
          getAuth().currentUser.uid,
          'referrals'
        ),
        snapshot => {
          const referralsUsed = [];
          snapshot.docs.map(async referralSnapshot => {
            const { type, newMemberId, referredByMemberId, timestamp } =
              referralSnapshot.data();
            if (type === 'referralOfferedWasUsed') {
              const { firstName, lastName } = (
                await getDoc(doc(getFirestore(), 'members', newMemberId))
              ).data();
              referralsUsed.push({
                name: `${firstName} ${lastName}`,
                timestamp: timestamp.toMillis(),
                id: referralSnapshot.id
              });
            } else if (type === 'referralFromMemberUsed') {
              const { firstName, lastName } = (
                await getDoc(doc(getFirestore(), 'members', referredByMemberId))
              ).data();
              setReferredBy({
                name: `${firstName} ${lastName}`,
                timestamp: timestamp.toMillis()
              });
            }
          });
          setReferralsUsed(referralsUsed);
        }
      );
    };

    if (getAuth().currentUser) {
      getReferrals();
    }
  }, []);

  useEffect(() => {
    const getExperiences = async () => {
      setLoadingMessage('Loading experiences...');
      const experiencesSnapshot = await getDocs(
        query(
          collection(getFirestore(), 'experiences'),
          where('memberId', '==', getAuth().currentUser.uid)
        )
      );
      const experiencesData = [];
      for (let experienceSnapshot of experiencesSnapshot.docs) {
        const imageRef = ref(
          getStorage(),
          `experiences/${getAuth().currentUser.uid}/${
            experienceSnapshot.id
          }_${imageDimension}x${imageDimension}.jpeg`
        );

        const participantNames = [];
        const participantsSnapshot = await getDocs(
          collection(
            getFirestore(),
            'experiences',
            experienceSnapshot.id,
            'participants'
          )
        );

        for (const participant of participantsSnapshot.docs) {
          participantNames.push(
            `${participant.data().firstName} ${participant.data().lastName}`
          );
        }

        experiencesData.push({
          ...experienceSnapshot.data(),
          imageRef,
          id: experienceSnapshot.id,
          participantNames
        });
      }

      setExperiences({
        past: experiencesData.filter(experience =>
          dayjs({
            day: experience.dates.end.date,
            month: experience.dates.end.month,
            year: experience.dates.end.year
          }).isBefore(dayjs().subtract(1, 'day').endOf('day'))
        ),
        upcoming: experiencesData.filter(experience =>
          dayjs({
            day: experience.dates.start.date,
            month: experience.dates.start.month,
            year: experience.dates.start.year
          }).isSameOrAfter(dayjs().startOf('day'))
        )
      });
    };

    if (getAuth().currentUser && imageDimension) {
      getExperiences();
    }
  }, [imageDimension, userData]);

  useEffect(() => {
    const getProducts = async () => {
      setLoadingMessage('Loading products...');
      const productsSnapshot = await getDocs(
        query(
          collection(getFirestore(), 'products'),
          where('memberId', '==', getAuth().currentUser.uid)
        )
      );
      const productsData = [];
      for (let productSnapshot of productsSnapshot.docs) {
        let image;
        try {
          image = await getDownloadURL(
            ref(
              getStorage(),
              `products/${getAuth().currentUser.uid}/${
                productSnapshot.id
              }_${imageDimension}x${imageDimension}.jpeg`
            )
          );
        } catch (error) {
          console.log(error);
          image = `https://picsum.photos/${imageDimension}`;
        }

        const variantsSnapshot = await getDocs(
          collection(getFirestore(), 'products', productSnapshot.id, 'variants')
        );
        const variants = [];
        for (let variantSnapshot of variantsSnapshot.docs) {
          variants.push(variantSnapshot.data());
        }

        productsData.push({
          ...productSnapshot.data(),
          image,
          id: productSnapshot.id,
          variants
        });
      }

      setProducts(productsData);
    };

    if (getAuth().currentUser && imageDimension) {
      getProducts();
    }
  }, [imageDimension, userData]);

  useEffect(() => {
    const getProfilePhoto = async () => {
      setLoadingMessage('Loading profile image...');
      let url;
      try {
        url = await getDownloadURL(
          ref(
            getStorage(),
            `members/${
              getAuth().currentUser.uid
            }/profile_${imageDimension}x${imageDimension}.jpeg`
          )
        );
        setProfileImageUrl(url);
      } catch (error) {
        setProfileImageUrl(`https://picsum.photos/${imageDimension}`);
      }
    };
    if (imageDimension && getAuth().currentUser) {
      getProfilePhoto();
    }
  }, [imageDimension, userData]);

  useEffect(() => {
    const getFeaturedImages = async () => {
      setLoadingMessage('Loading featured images...');
      // get featured images
      const results = await listAll(
        ref(
          getStorage(),
          `members/${getAuth().currentUser.uid}/featured-images`
        )
      );
      const featuredImagesData = [];
      for (let refOfFeaturedImage of results.items) {
        const url = await getDownloadURL(refOfFeaturedImage);
        featuredImagesData.push({ ref: refOfFeaturedImage, url });
      }
      setFeaturedImages(featuredImagesData);
    };
    if (imageDimension && getAuth().currentUser) {
      getFeaturedImages();
    }
  }, [imageDimension, userData]);

  useEffect(() => {
    const getBalance = async () => {
      setLoadingMessage('Loading account balance...');
      const retrieveBalance = httpsCallable(getFunctions(), 'retrieveBalance');
      const balanceResult = await retrieveBalance();
      setBalance(balanceResult.data);
    };

    if (getAuth().currentUser) {
      getBalance();
    }
  }, []);

  useEffect(() => {
    const getAccount = async () => {
      setLoadingMessage('Getting account details...');
      const retrieveAccount = httpsCallable(getFunctions(), 'retrieveAccount');
      const accountRes = await retrieveAccount();
      setStripeAccount(accountRes.data);
    };

    if (getAuth().currentUser) {
      getAccount();
    }
  }, []);

  useEffect(() => {
    const loadSupportCountries = async () => {
      setLoadingMessage('Fetching supported countries...');
      const supportedCountries = await getSupportedStripeCountries();
      const formattedCountries = Object.keys(supportedCountries).map(
        countryCode => ({ label: supportedCountries[countryCode], countryCode })
      );
      setCountryCodes(formattedCountries);
    };

    if (!stripeAccount) {
      loadSupportCountries();
    }
  }, [stripeAccount]);

  let dashboard;
  if (!userData || !profileImageUrl || !balance || !experiences || !products) {
    dashboard = <Loader status={loadingMessage} />;
  } else {
    const generateExperienceCards = experiences => {
      if (experiences.length === 0) {
        return (
          <Typography variant="body1" gutterBottom>
            None yet. Create one! 🐠
          </Typography>
        );
      }

      return (
        <Grid container spacing={3}>
          {experiences.map(experience => (
            <Grid item xs={12} sm={6} key={experience.id}>
              <Experience
                title={experience.title}
                price={experience.price}
                startDate={experience.dates.start}
                endDate={experience.dates.end}
                imageRef={experience.imageRef}
                description={experience.description}
                prerequisites={experience.prerequisites}
                maxParticipants={experience.participants.max}
                participants={experience.participantNames}
                type={experience.type}
                experienceId={experience.id}
                nonrefundableDeposit={experience.nonrefundableDeposit}
                place={experience.place}
                imageDimension={imageDimension}
                uid={getAuth().currentUser.uid}
              />
            </Grid>
          ))}
        </Grid>
      );
    };

    const generateProductCards = products => {
      if (products.length === 0) {
        return (
          <Typography variant="body1" gutterBottom>
            None.
          </Typography>
        );
      }

      return (
        <Grid container spacing={3}>
          {products.map(product => (
            <Grid item xs={12} sm={6} key={product.id}>
              <Product
                title={product.title}
                imageUrl={product.image}
                description={product.description}
                variants={product.variants}
                productId={product.id}
                imageDimension={imageDimension}
                uid={getAuth().currentUser.uid}
              />
            </Grid>
          ))}
        </Grid>
      );
    };

    const stripeDashboard = () => {
      if (!stripeAccount) {
        return (
          <>
            <Alert severity="warning" sx={{ mb: 3 }}>
              <AlertTitle>
                Setup your Stripe account so we can send you money
              </AlertTitle>
              Stripe is the secure platform we use for all payments. Synergy
              Diving has no access to your personal financial details. It works
              a lot like PayPal; you privately setup all your bank details, then
              we use your email as your ID, and your payouts will auto-magically
              end up in your bank account 💰
            </Alert>
            {countryCodes ? (
              <Container maxWidth="sm">
                <Stack spacing={3}>
                  <Typography variant="body1">
                    Where are you or your company based?
                  </Typography>
                  <Autocomplete
                    value={countryCode}
                    onChange={(event, newValue) => {
                      setCountryCode(newValue);
                    }}
                    id="combo-box-demo"
                    options={countryCodes}
                    renderInput={params => (
                      <TextField {...params} label="Countries" />
                    )}
                  />
                  <Button
                    variant="contained"
                    onClick={async () => {
                      setSnackbar({
                        open: true,
                        message:
                          'Connecting to Stripe. You will be redirected shortly...',
                        duration: 8800
                      });
                      // setLoadingMessage('Creating account financials...');
                      const createExpressAccount = httpsCallable(
                        getFunctions(),
                        'createExpressAccount'
                      );
                      const stripeId = await createExpressAccount({
                        email: getAuth().currentUser.email,
                        country: countryCode.countryCode
                      });

                      // setLoadingMessage('Saving account data...');
                      await updateDoc(
                        doc(
                          getFirestore(),
                          'members',
                          getAuth().currentUser.uid
                        ),
                        { stripeId: stripeId.data }
                      );

                      // setLoadingMessage('Generating onboarding link...');
                      const createOnboardingLink = httpsCallable(
                        getFunctions(),
                        'createOnboardingLink'
                      );
                      const url = await createOnboardingLink();
                      // setLoadingMessage('Redirecting...');
                      window.location.href = url.data;
                    }}
                  >
                    Setup Stripe account
                  </Button>
                </Stack>
              </Container>
            ) : (
              <LinearProgress />
            )}
          </>
        );
      } else if (!stripeAccount.charges_enabled) {
        return (
          <Stack>
            <Typography gutterBottom>
              Click the button below to continue your Stripe account setup.
            </Typography>
            <Box>
              <Button
                color="primary"
                variant="contained"
                sx={{ my: 3 }}
                onClick={async () => {
                  setSnackbar({
                    open: true,
                    message: 'One moment, redirecting to your account...',
                    duration: 8800
                  });

                  const createOnboardingLink = httpsCallable(
                    getFunctions(),
                    'createOnboardingLink'
                  );
                  const result = await createOnboardingLink();
                  window.location.href = result.data;
                }}
              >
                Continue setup
              </Button>
            </Box>
          </Stack>
        );
      } else {
        return (
          <>
            {balance.available && (
              <Typography variant="subtitle1">
                Available balance:{' '}
                {(balance.available[0].amount / 100).toFixed(2)}{' '}
                {balance.available[0].currency.toUpperCase()}
              </Typography>
            )}
            {balance.pending && (
              <Typography variant="subtitle1">
                Pending balance: {(balance.pending[0].amount / 100).toFixed(2)}{' '}
                {balance.pending[0].currency.toUpperCase()}
              </Typography>
            )}
            <Button
              color="primary"
              variant="outlined"
              size="small"
              sx={{ my: 3 }}
              onClick={async () => {
                setSnackbar({
                  open: true,
                  message: 'One moment, redirecting to your account...',
                  duration: 8800
                });

                const createLoginLink = httpsCallable(
                  getFunctions(),
                  'createLoginLink'
                );
                const result = await createLoginLink();
                window.location.href = result.data.url;
              }}
            >
              View account details
            </Button>
          </>
        );
      }
    };

    dashboard = (
      <>
        <Header />
        <Grid
          container
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            minHeight: '100vh'
          }}
          justifyContent="center"
        >
          <Grid item xs={12} md={11} lg={8}>
            <Paper
              sx={{
                p: 2,
                mt: 11,
                mb: 3,
                mx: 1
              }}
            >
              {stripeAccount && !stripeAccount.charges_enabled && (
                <Alert
                  severity="warning"
                  sx={{ mb: 3 }}
                  action={
                    tab === '4' ? null : (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setTab('4')}
                      >
                        Continue
                      </Button>
                    )
                  }
                >
                  <AlertTitle>Stripe setup incomplete</AlertTitle>
                  Please complete it so participants can book your courses and
                  we can pay you out 🤑
                </Alert>
              )}
              {!isProfileReady(userData) && (
                <Alert severity="warning" sx={{ mb: 3 }}>
                  <AlertTitle>Profile incomplete</AlertTitle>
                  For your profile to go live, you need to have the following
                  completed: first name, last name, bio, Stripe setup, profile
                  photo, and at least one featured image.
                </Alert>
              )}

              {!stripeAccount && (
                <Alert
                  severity="warning"
                  sx={{ mb: 3 }}
                  action={
                    tab === '4' ? null : (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setTab('4')}
                      >
                        setup
                      </Button>
                    )
                  }
                >
                  To start receiving payments, please setup your Stripe account.
                </Alert>
              )}

              <Grid container direction={mediumUp ? 'row' : 'column-reverse'}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    Hi {`${userData.firstName} ${userData.lastName}`}!
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" sx={{ marginRight: 1 }}>
                      {getAuth().currentUser.email}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setNewEmail(getAuth().currentUser.email);
                        setUpdateEmailAddress(true);
                      }}
                    >
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Dialog
                    open={updateEmailAddress}
                    onClose={() => setUpdateEmailAddress(false)}
                  >
                    <DialogTitle>Update email address</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        What would you like to update your email address to?
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={newEmail}
                        onChange={evt => setNewEmail(evt.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setUpdateEmailAddress(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={async () => {
                          setUpdateEmailAddress(false);
                          try {
                            await updateEmail(getAuth().currentUser, newEmail);
                            setSnackbar({
                              open: true,
                              message:
                                'Your email address has been successfully updated.',
                              duration: 8800
                            });
                          } catch (error) {
                            if (error.code === 'auth/invalid-email') {
                              setSnackbar({
                                open: true,
                                message: `This email address "${newEmail}"" is invalid.`,
                                duration: 8800
                              });
                            } else if (
                              error.code === 'auth/email-already-in-use'
                            ) {
                              setSnackbar({
                                open: true,
                                message: `Sorry the email address "${newEmail}" is already in use.`,
                                duration: 8800
                              });
                            } else {
                              setSnackbar({
                                open: true,
                                message: `There is something wrong with the email "${newEmail}": ${error.code}`,
                                duration: 8800
                              });
                            }
                          }
                        }}
                        color="primary"
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: 'right', mb: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() =>
                      navigate(`/profiles/${getAuth().currentUser.uid}`)
                    }
                  >
                    View public profile
                  </Button>
                </Grid>
              </Grid>

              <Paper sx={{ my: 3 }}>
                <TabContext value={tab}>
                  <TabList
                    onChange={(event, newValue) => setTab(newValue)}
                    variant={smallUp ? 'fullWidth' : 'scrollable'}
                    scrollButtons="auto"
                    centered={smallUp}
                  >
                    <Tab label="Profile" value="1" />
                    <Tab label="Experiences" value="2" />
                    <Tab label="Products" value="3" />
                    <Tab label="Earnings" value="4" />
                  </TabList>
                  <TabPanel value="1">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} sx={{ marginBottom: 3 }}>
                        <Avatar
                          alt={userData.firstName}
                          variant="rounded"
                          sx={{
                            width: '100%',
                            height: '100%',
                            mb: 1
                          }}
                          src={
                            profileImageUrl
                              ? profileImageUrl
                              : `https://picsum.photos/${imageDimension}`
                          }
                        />
                        <ImageEditor
                          initialImage={profileImageUrl}
                          setEditImage={setEditProfileImage}
                          editImage={editProfileImage}
                          editedImage={async blob => {
                            setShowBackdrop(true);
                            setSnackbar({
                              open: true,
                              message: 'Updating your profile image...',
                              duration: 11000
                            });
                            await uploadBytes(
                              ref(
                                getStorage(),
                                `members/${
                                  getAuth().currentUser.uid
                                }/profile.jpeg`
                              ),
                              blob
                            );

                            setShowBackdrop(false);
                          }}
                        />
                        <Button
                          onClick={() => setEditProfileImage(true)}
                          variant="outlined"
                        >
                          Edit profile image
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="First Name"
                          variant="outlined"
                          fullWidth
                          value={firstName}
                          style={{ marginBottom: 11, marginTop: 11 }}
                          onChange={evt => setFirstName(evt.target.value)}
                          onBlur={evt => {
                            updateDoc(
                              doc(
                                getFirestore(),
                                'members',
                                getAuth().currentUser.uid
                              ),
                              {
                                firstName,
                                lastUpdated: Timestamp.now()
                              }
                            );
                            setSnackbar({
                              open: true,
                              message: 'First name updated',
                              duration: 1100
                            });
                          }}
                        />
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          value={lastName}
                          style={{ marginBottom: 11 }}
                          onChange={evt => setLastName(evt.target.value)}
                          onBlur={evt => {
                            updateDoc(
                              doc(
                                getFirestore(),
                                'members',
                                getAuth().currentUser.uid
                              ),
                              {
                                lastName,
                                lastUpdated: Timestamp.now()
                              }
                            );
                            setSnackbar({
                              open: true,
                              message: 'Last name updated',
                              duration: 1100
                            });
                          }}
                        />
                        <TextField
                          label="Bio"
                          variant="outlined"
                          fullWidth
                          value={bio}
                          multiline
                          rows={8}
                          style={{ marginBottom: 11 }}
                          onChange={evt => setBio(evt.target.value)}
                          onBlur={evt => {
                            updateDoc(
                              doc(
                                getFirestore(),
                                'members',
                                getAuth().currentUser.uid
                              ),
                              {
                                bio,
                                lastUpdated: Timestamp.now()
                              }
                            );
                            setSnackbar({
                              open: true,
                              message: 'Bio updated',
                              duration: 1100
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 33, marginBottom: 22 }} />
                    <Typography variant="h6">Verifications</Typography>
                    <Button
                      startIcon={<Add />}
                      style={{ margin: 11 }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setNewVerification('');
                        setAddVerification(true);
                      }}
                    >
                      Add
                    </Button>
                    <Dialog
                      open={addVerification}
                      onClose={() => setAddVerification(false)}
                    >
                      <DialogTitle>Add verification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          You can add a verification below. You will be required
                          to provide documentation for it to be shown on your
                          public profile.
                        </DialogContentText>
                        <DialogContentText>
                          Verifications should be of the format [certifying
                          body] [title (no acronyms here)]. For example, "PADI
                          Open Water Scuba Instructor".
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="verification"
                          label="What would you like to be verified for?"
                          fullWidth
                          value={newVerification}
                          onChange={evt => setNewVerification(evt.target.value)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setAddVerification(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            addDoc(
                              collection(
                                getFirestore(),
                                'members',
                                getAuth().currentUser.uid,
                                'verifications'
                              ),
                              {
                                verified: false,
                                assertion: newVerification
                              }
                            );

                            setAddVerification(false);
                          }}
                          color="primary"
                        >
                          Add
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Grid container spacing={3}>
                      {Object.keys(verifications).map(verificationId => {
                        let cardContent = (
                          <CardContent>
                            <Typography variant="body2">
                              You have been verified!
                            </Typography>
                          </CardContent>
                        );
                        if (!verifications[verificationId].fileType) {
                          cardContent = (
                            <CardContent>
                              <Typography variant="body2">
                                Please upload a supporting document.
                              </Typography>
                            </CardContent>
                          );
                        } else if (!verifications[verificationId].verified) {
                          cardContent = (
                            <CardContent>
                              <Typography variant="body2" gutterBottom>
                                Document uploaded ✅
                              </Typography>
                              <Typography variant="body2">
                                This is pending verification.
                              </Typography>
                            </CardContent>
                          );
                        }

                        return (
                          <Grid item xs={12} sm={6} key={verificationId}>
                            <Card>
                              <CardHeader
                                avatar={
                                  verifications[verificationId].verified ? (
                                    <VerifiedUser
                                      style={{
                                        color: theme.palette.primary.main
                                      }}
                                    />
                                  ) : (
                                    <Warning />
                                  )
                                }
                                title={verifications[verificationId].assertion}
                              />
                              {cardContent}
                              <CardActions>
                                {!verifications[verificationId].fileType && (
                                  <>
                                    <input
                                      color="primary"
                                      type="file"
                                      onChange={async event => {
                                        setSnackbar({
                                          open: true,
                                          message:
                                            'Uploading verification document...',
                                          duration: 11000
                                        });
                                        const fileType =
                                          event.target.files[0].name.match(
                                            /(.+)\.(.+)/
                                          );
                                        await uploadBytes(
                                          ref(
                                            getStorage(),
                                            `/verifications/${
                                              getAuth().currentUser.uid
                                            }/${verificationId}.${fileType[2]}`
                                          ),
                                          event.target.files[0]
                                        );
                                        await updateDoc(
                                          doc(
                                            getFirestore(),
                                            'members',
                                            getAuth().currentUser.uid,
                                            'verifications',
                                            verificationId
                                          ),
                                          {
                                            fileType: fileType[2]
                                          }
                                        );
                                        await updateDoc(
                                          doc(
                                            getFirestore(),
                                            'members',
                                            getAuth().currentUser.uid
                                          ),
                                          {
                                            lastUpdated: Timestamp.now()
                                          }
                                        );
                                        setSnackbar({
                                          open: true,
                                          message:
                                            'Verification document successfully uploaded!',
                                          duration: 3300
                                        });
                                      }}
                                      id={`support-document-${verificationId}`}
                                      style={{ display: 'none' }}
                                    />
                                    <label
                                      htmlFor={`support-document-${verificationId}`}
                                    >
                                      <Button
                                        variant="outlined"
                                        component="span"
                                        size="small"
                                        startIcon={<CloudUpload />}
                                      >
                                        Upload document
                                      </Button>
                                    </label>
                                  </>
                                )}
                                {verifications[verificationId].fileType && (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={<Download />}
                                    href={
                                      verifications[verificationId].downloadUrl
                                    }
                                  >
                                    Download
                                  </Button>
                                )}
                                <Button
                                  variant="outlined"
                                  size="small"
                                  startIcon={<Delete />}
                                  onClick={() => {
                                    deleteDoc(
                                      doc(
                                        getFirestore(),
                                        'members',
                                        getAuth().currentUser.uid,
                                        'verifications',
                                        verificationId
                                      )
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Divider style={{ marginTop: 33, marginBottom: 22 }} />
                    <Typography variant="h6">Featured images</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      Upload up to four of your best images.
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      Status: {featuredImages.length}/4
                    </Typography>
                    <>
                      <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        disabled={featuredImages.length === 4}
                        onChange={async event => {
                          setSnackbar({
                            open: true,
                            message: 'Adding featured image...',
                            duration: 11000
                          });
                          setShowBackdrop(true);

                          await uploadBytes(
                            ref(
                              getStorage(),
                              `members/${
                                getAuth().currentUser.uid
                              }/featured-images/${event.target.files[0].name}`
                            ),
                            event.target.files[0]
                          );
                        }}
                        id="featured-image-file"
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="featured-image-file">
                        <Button
                          variant="outlined"
                          disabled={featuredImages.length === 4}
                          component="span"
                          size="small"
                          startIcon={<CloudUpload />}
                        >
                          Upload photo
                        </Button>
                      </label>
                    </>
                    <ImageList cellHeight={222} style={{ marginTop: 33 }}>
                      {featuredImages.map((image, i) => (
                        <ImageListItem key={image.ref.name}>
                          <img src={image.url} alt={image.ref.name} />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton
                                style={{ color: theme.palette.primary.main }}
                                onClick={() => {
                                  setSnackbar({
                                    open: true,
                                    message: 'Deleting featured image...',
                                    duration: 3300
                                  });
                                  setShowBackdrop(true);
                                  deleteObject(image.ref);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6">Referrals</Typography>
                    {referredBy && (
                      <Typography variant="body1" gutterBottom>
                        You used a referral code from {referredBy.name} on{' '}
                        {dayjs(referredBy.timestamp).format('LL')}.
                      </Typography>
                    )}
                    <Typography variant="subtitle2" gutterBottom>
                      Share your referral link below to earn great benefits!
                    </Typography>
                    <TextField
                      sx={{ my: 3 }}
                      variant="outlined"
                      value={`${window.location.origin}/member/signup/${
                        getAuth().currentUser.uid
                      }`}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="contained"
                              edge="end"
                              onClick={() => {
                                setSnackbar({
                                  open: true,
                                  message: 'Referral link copied',
                                  duration: 1111
                                });
                                navigator.clipboard.writeText(
                                  `${window.location.origin}/member/signup/${
                                    getAuth().currentUser.uid
                                  }`
                                );
                              }}
                            >
                              Copy
                            </Button>
                          </InputAdornment>
                        )
                      }}
                      label="Referral link"
                    />
                    {referralsUsed.length > 0 && (
                      <Typography variant="body1">
                        Members that used your referral code...
                      </Typography>
                    )}
                    <List>
                      {referralsUsed.map(referral => (
                        <ListItem key={referral.id}>
                          <ListItemText
                            primary={referral.name}
                            secondary={`Signed up ${dayjs(
                              referral.timestamp
                            ).format('LL')}`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </TabPanel>
                  <TabPanel value="2">
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginBottom: 33 }}
                      onClick={() => setShowExperienceDialog(true)}
                    >
                      Add Experience
                    </Button>
                    <Typography variant="h6" gutterBottom>
                      Upcoming experiences
                    </Typography>
                    {experiences.upcoming.length > 0 && (
                      <Alert
                        severity="info"
                        sx={{ mb: 3 }}
                        action={
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => navigate('/faq')}
                          >
                            learn more
                          </Button>
                        }
                      >
                        <AlertTitle>
                          Keep your booking numbers up-to-date
                        </AlertTitle>
                        Synergy Diving will automatically update the number of
                        spaces left after each booking. But if you also accept
                        bookings elsewhere, make sure to manually update the
                        spots available here to avoid over-bookings.
                      </Alert>
                    )}
                    {generateExperienceCards(experiences.upcoming)}
                    <Divider style={{ margin: 33 }} />
                    <Typography variant="h6" gutterBottom>
                      Past experiences
                    </Typography>
                    {generateExperienceCards(experiences.past)}
                  </TabPanel>
                  <TabPanel value="3">
                    <Alert severity="info" sx={{ mb: 3 }}>
                      <Typography variant="body2" gutterBottom>
                        If you'd like to sell physical products, such as photos
                        or t-shirts, then this is the perfect place to do it!
                      </Typography>
                      <Typography variant="body2">
                        If you'd like to run a dive experience for particular
                        dates, then please make sure to list it under
                        "experiences". That way we can send important email
                        reminders to your participants after they book it.
                      </Typography>
                    </Alert>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginBottom: 33 }}
                      onClick={() => setShowProductDialog(true)}
                    >
                      Add Product
                    </Button>
                    {generateProductCards(products)}
                  </TabPanel>
                  <TabPanel value="4">{stripeDashboard()}</TabPanel>
                </TabContext>
              </Paper>
            </Paper>
          </Grid>
        </Grid>
        <Footer />
      </>
    );
  }

  return (
    <>
      {dashboard}
      {showExperienceDialog && (
        <ExperienceData
          uid={getAuth().currentUser.uid}
          showExperienceDialog={showExperienceDialog}
          dialogTitle="Create a new experience"
          onSave={async ({
            title,
            startDate,
            endDate,
            description,
            placeId,
            prerequisites,
            maxParticipants,
            price,
            experienceImage,
            type,
            nonrefundableDeposit
          }) => {
            const placeDetails = await geocode(placeId);

            const res = await addDoc(
              collection(getFirestore(), 'experiences'),
              {
                price: parseFloat(price),
                nonrefundableDeposit,
                dates: {
                  start: {
                    date: startDate.date(),
                    month: startDate.month(),
                    year: startDate.year()
                  },
                  end: {
                    date: endDate.date(),
                    month: endDate.month(),
                    year: endDate.year()
                  }
                },
                description,
                place: {
                  id: placeId,
                  address: placeDetails.formatted_address,
                  location: new GeoPoint(
                    placeDetails.geometry.location.lat(),
                    placeDetails.geometry.location.lng()
                  )
                },
                prerequisites,
                participants: {
                  max: parseInt(maxParticipants, 10),
                  enrolled: 0
                },
                title,
                type,
                memberId: getAuth().currentUser.uid
              }
            );

            return await uploadBytes(
              ref(
                getStorage(),
                `experiences/${getAuth().currentUser.uid}/${res.id}.jpeg`
              ),
              experienceImage
            );
          }}
          closeDialog={updatedExperiences => {
            setShowExperienceDialog(false);
            if (updatedExperiences) {
              setSnackbar({
                open: true,
                message: 'Updating your experiences...',
                duration: 11000
              });
            }
          }}
          imageDimension={imageDimension}
        />
      )}
      {showProductDialog && (
        <ProductData
          uid={getAuth().currentUser.uid}
          showProductDialog={showProductDialog}
          dialogTitle="Add a new product"
          onSave={async ({ title, description, productImage, variants }) => {
            const res = await addDoc(collection(getFirestore(), 'products'), {
              description,
              title,
              memberId: getAuth().currentUser.uid
            });
            for (const variant of variants) {
              await addDoc(
                collection(getFirestore(), 'products', res.id, 'variants'),
                {
                  ...variant
                }
              );
            }

            await uploadBytes(
              ref(
                getStorage(),
                `products/${getAuth().currentUser.uid}/${res.id}.jpeg`
              ),
              productImage
            );

            return updateDoc(
              doc(getFirestore(), 'members', getAuth().currentUser.uid),
              {
                lastUpdated: Timestamp.now()
              }
            );
          }}
          closeDialog={addedProduct => {
            setShowProductDialog(false);
            if (addedProduct) {
              setSnackbar({
                open: true,
                message: 'Adding product...',
                duration: 11000
              });
            }
          }}
          imageDimension={imageDimension}
        />
      )}
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
      />
      <Backdrop
        open={showBackdrop}
        style={{ color: theme.palette.primary.light, zIndex: 11 }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Dashboard;
