import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  DialogTitle,
  Box,
  DialogActions,
  Dialog,
  DialogContent,
  Divider,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
  onAuthStateChanged
} from 'firebase/auth';
// import { logEvent } from 'firebase/analytics';
import Loader from './Loader';
import logo from '../images/synergy-diving-logo.png';
import backgroundImage from '../images/dolphins.jpeg';

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down('lg'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});
  const [processing, setProcessing] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [forgottenEmail, setForgottenEmail] = useState('');

  useEffect(() => {
    const unsub = onAuthStateChanged(getAuth(), user => {
      if (user) {
        navigate('/member/dashboard', { replace: true });
      }
    });

    return () => unsub();
  }, [navigate]);

  const attemptToLogin = async () => {
    setProcessing(true);
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
    } catch (error) {
      console.log(error);
      setProcessing(false);
      if (error.code === 'auth/wrong-password') {
        setError({
          password: 'Your password is incorrect. Please try again.'
        });
      }
      if (error.code === 'auth/user-not-found') {
        setError({
          email: "We couldn't find your email address. Please check it again."
        });
      }
      if (error.code === 'auth/invalid-email') {
        setError({
          email: "Your email address doesn't look right. Please check it again."
        });
      }
    }
  };

  let content;
  if (processing) {
    content = <Loader status="Logging in..." />;
  } else {
    content = (
      <Grid
        container
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12} md={9} lg={6}>
          <Paper
            sx={{
              padding: screen ? 2 : 3,
              marginTop: screen ? 2 : 5,
              marginLeft: screen ? 1 : 8,
              marginRight: screen ? 1 : 8
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
                cursor: 'pointer'
              }}
              onClick={() => navigate('/', { replace: true })}
            >
              <img
                src={logo}
                alt="Synergy Diving logo"
                style={{ width: 44, marginRight: 11 }}
              />
              <Typography variant="h5">Synergy Diving</Typography>
            </Box>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 3, mb: 2 }}>
              Log in
            </Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { mb: 3 }
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                autoFocus
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                error={!!error.email}
                helperText={error.email}
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                error={!!error.password}
                helperText={error.password}
                type="password"
                value={password}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    attemptToLogin();
                  }
                }}
                onChange={evt => setPassword(evt.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={password === '' || email === ''}
                onClick={() => attemptToLogin()}
              >
                Login
              </Button>
            </Box>
            <Divider sx={{ marginTop: 3, marginBottom: 1 }} />
            <Button onClick={() => setShowDialog(true)} size="small">
              Forgot password?
            </Button>
            <Button
              onClick={() => navigate('/member/signup', { replace: true })}
              size="small"
            >
              Create account
            </Button>
            <Dialog open={showDialog}>
              <DialogTitle>Forgot your password?</DialogTitle>
              <DialogContent>
                If you've forgotten your password, or simply want to change it,
                type in your email address below. If we have your email address
                in our system, we'll send you an email now.
              </DialogContent>
              <DialogContent>
                <TextField
                  label="Your email address"
                  autoFocus
                  variant="outlined"
                  fullWidth
                  value={forgottenEmail}
                  onChange={evt => setForgottenEmail(evt.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    setShowDialog(false);
                    setForgottenEmail('');
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={forgottenEmail === ''}
                  onClick={async () => {
                    setShowDialog(false);
                    try {
                      await sendPasswordResetEmail(getAuth(), forgottenEmail);
                      setForgottenEmail('');
                    } catch (error) {
                      console.log(error);
                      setShowDialog(false);
                      setForgottenEmail('');
                    }
                  }}
                  color="primary"
                >
                  Send reset email
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return content;
};

export default Login;
