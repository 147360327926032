import React, { useState } from 'react';
import { Grid, Paper, TextField, Button, LinearProgress } from '@mui/material';
import Markdown from 'react-markdown';
import Lottie from 'react-lottie-player';
import { getFunctions, httpsCallable } from 'firebase/functions';

const AIResponseSection = () => {
  const [prompt, setPrompt] = useState('');
  const [aiResponse, setAiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAskQuestion = async () => {
    if (!prompt.trim()) return;

    setIsLoading(true);
    setAiResponse(null);

    const functions = getFunctions();
    const freediverResponse = httpsCallable(functions, 'chatResponse');

    try {
      const aiFreediverResponse = await freediverResponse({
        messages: [
          {
            role: 'system',
            content: "You are the world's most experienced freediver"
          },
          {
            role: 'user',
            content: prompt
          }
        ]
      });

      setAiResponse(aiFreediverResponse.data.content);
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setAiResponse('Sorry, there was an error processing your request.');
    } finally {
      setIsLoading(false);
      setPrompt('');
    }
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={5}>
        <Paper sx={{ p: 3 }}>
          <TextField
            variant="outlined"
            label="Your question"
            multiline
            minRows={5}
            sx={{ width: '100%', mb: 3 }}
            value={prompt}
            onChange={event => setPrompt(event.target.value)}
            disabled={isLoading}
          />
          <Button
            variant="contained"
            sx={{ width: '100%' }}
            onClick={handleAskQuestion}
            disabled={isLoading || !prompt.trim()}
          >
            {aiResponse ? 'Ask a new question' : 'Ask'}
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Paper sx={{ p: 3, minHeight: 111 }}>
          {isLoading ? (
            <LinearProgress />
          ) : aiResponse ? (
            <Markdown>{aiResponse}</Markdown>
          ) : (
            <Lottie
              loop
              animationData={require('../lottie/underwater-ocean-fish-and-turtle.json')}
              play
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AIResponseSection;
