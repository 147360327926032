import React from 'react';
import { Typography, Grid, Paper, Link } from '@mui/material';
import Lottie from 'react-lottie-player';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import backgroundImage from '../images/freediving-with-fish.jpeg';

// lottie sea turtle source https://lottiefiles.com/8194-sea-turtle

export default function Mission() {
  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 11,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          marginTop: 33
        }}
      >
        <Grid item md={8} lg={8}>
          <Paper
            style={{
              marginTop: 88,
              padding: 22
            }}
          >
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" gutterBottom>
              We'd love to hear from you!
            </Typography>
            <Typography variant="body1">
              You can email us at{' '}
              <Link href="mailto:hello@synergydiving.com">
                hello@synergydiving.com
              </Link>
            </Typography>
            <Lottie
              loop
              style={{ width: 333 }}
              animationData={require('../lottie/sea-turtle.json')}
              play
            />
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
