import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import backgroundImage from '../images/freediving-with-fish.jpeg';

export default function Mission() {
  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 11,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          marginTop: 33
        }}
      >
        <Grid item md={8} lg={8}>
          <Paper
            style={{
              marginTop: 88,
              padding: 22
            }}
          >
            <Typography variant="h4" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" gutterBottom>
              At Synergy Diving, it’s our philosophy that{' '}
              <strong>
                everyone in the diving community deserves a fair go
              </strong>
              .
            </Typography>
            <Typography variant="body1" gutterBottom>
              We believe that{' '}
              <strong>
                freelance instructors, photographers, and boat captains
              </strong>{' '}
              should have an equal opportunity to not only work, but thrive,
              within an increasingly competitive industry dominated by dive
              shops.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We also feel strongly that customers should have{' '}
              <strong>
                more choice, more flexibility, better quality, and at a more
                competitive price point
              </strong>{' '}
              than what currently exists.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Together, with our <strong>fit-for-purpose booking system</strong>
              , we are excited to offer the diving community with{' '}
              <strong>a better way to connect, earn, and play</strong>.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving is a platform built by divers for divers.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
