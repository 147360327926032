import React, { useState } from 'react';
import {
  Grid,
  Avatar,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Typography,
  InputAdornment,
  IconButton,
  Alert
} from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';
import { doc, getFirestore, updateDoc, Timestamp } from 'firebase/firestore';

export default function ProductData(props) {
  const {
    uid,
    showProductDialog,
    dialogTitle,
    onSave,
    closeDialog,
    imageDimension,
    initialProductImage,
    initialTitle = '',
    initialDescription = '',
    initialVariants = []
  } = props;

  // product data
  const [productImage, setProductImage] = useState(initialProductImage);
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [variants, setVariants] = useState(initialVariants);

  const generateVariant = (index, { title, price }) => {
    return (
      <Grid container style={{ marginBottom: 11 }} key={index}>
        <Grid item xs={5}>
          <TextField
            value={title}
            inputProps={{ maxLength: 22 }}
            placeholder="e.g. XL or blue"
            label="Variant name"
            onChange={evt => {
              const updatedVariants = [...variants];
              updatedVariants[index].title = evt.target.value;
              setVariants(updatedVariants);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Price"
            value={price}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            type="number"
            onChange={evt => {
              const updatedVariants = [...variants];
              updatedVariants[index].price = parseFloat(evt.target.value);
              setVariants(updatedVariants);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          onClick={() => {
            let updatedVariants = [...variants];
            updatedVariants.splice(index, 1);
            setVariants(updatedVariants);
          }}
        >
          <IconButton aria-label="delete" size="large">
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      fullScreen
      open={showProductDialog}
      onClose={(evt, reason) => {
        console.log('closing...', reason);
        closeDialog();
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} style={{ marginTop: 22, padding: 11 }}>
            <Avatar
              alt={title}
              variant="rounded"
              style={{ width: '100%', height: '100%', marginBottom: 11 }}
              src={
                productImage
                  ? typeof productImage === 'string' &&
                    productImage.startsWith('https')
                    ? productImage
                    : URL.createObjectURL(productImage)
                  : `https://picsum.photos/${imageDimension}`
              }
            />
            <>
              <input
                color="primary"
                accept="image/*"
                type="file"
                onChange={async event => {
                  setProductImage(event.target.files[0]);
                }}
                id="icon-button-file"
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  size="small"
                  startIcon={<CloudUpload />}
                >
                  Upload photo
                </Button>
              </label>
            </>
          </Grid>
          <Grid item xs={12} md={6} style={{ marginTop: 22, padding: 11 }}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              style={{ marginBottom: 11, marginTop: 11 }}
              onChange={evt => setTitle(evt.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
              value={description}
              style={{ marginBottom: 11, marginTop: 11 }}
              onChange={evt => setDescription(evt.target.value)}
            />
            <Alert
              severity="info"
              sx={{ mb: 3 }}
              action={
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    setVariants([...variants, { title: '', price: 1 }])
                  }
                >
                  Add variant
                </Button>
              }
            >
              <Typography variant="body2" gutterBottom>
                Variants are the different options for your product. They may
                have a different price points.
              </Typography>
              <Typography variant="body2">
                For example, different sizes for t-shirts, or different
                materials on which you sell photographic prints.
              </Typography>
            </Alert>
            {variants.length > 0 && (
              <Typography variant="h6" gutterBottom>
                Variants
              </Typography>
            )}
            {variants.map((variant, i) => generateVariant(i, variant))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            closeDialog(true);
            await onSave({
              title,
              description,
              productImage,
              variants
            });
            await updateDoc(doc(getFirestore(), 'members', uid), {
              lastUpdated: Timestamp.now()
            });
          }}
          color="primary"
          disabled={!title || !variants.length >= 1 || !description}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
