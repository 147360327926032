/*
Uses https://developers.google.com/maps/documentation/javascript/reference/geometry#spherical.computeDistanceBetween
  */
const computeDistance = (from, to) =>
  window.google.maps.geometry.spherical.computeDistanceBetween(
    new window.google.maps.LatLng(from),
    new window.google.maps.LatLng(to)
  );

// geocode
// https://developers.google.com/maps/documentation/javascript/examples/geocoding-place-id
const geocode = async placeId => {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        placeId
      },
      (results, status) => {
        resolve(results[0]);
      }
    );
  });
};

export { computeDistance, geocode };
