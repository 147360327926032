import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
  Button
} from '@mui/material';
import { Event, LocationOn, Person } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Lottie from 'react-lottie-player';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import {
  getFirestore,
  query,
  getDocs,
  where,
  getDoc,
  doc,
  Timestamp,
  collection
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { experienceDuration, isProfileReady } from '../modules/utils';
import { geocode, computeDistance } from '../modules/maps';

dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(timezone);

const maxRadius = 333;

export default function SearchResults() {
  const theme = useTheme();
  const navigate = useNavigate();

  const searchQuery = new URLSearchParams(useLocation().search);
  const queryStartDate = parseInt(searchQuery.get('startDate'), 10);
  const queryExperienceType = searchQuery.get('experienceType');
  const placeId = searchQuery.get('placeId');

  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [experiences, setExperiences] = useState();
  const [placeDetails, setPlaceDetails] = useState();
  const [members, setMembers] = useState();

  // fetch place details that includes the bounds if they exist
  useEffect(() => {
    const fetchPlaceDetails = async () => {
      const pd = await geocode(placeId);
      setPlaceDetails(pd);
    };
    if (placeId) {
      fetchPlaceDetails();
    }
  }, [placeId]);

  useEffect(() => {
    // get experiences
    const getExperiences = async () => {
      const membersData = {};
      let experiencesRef = collection(getFirestore(), 'experiences');

      const fromNow = where(
        'timestamps.startDate',
        '>=',
        Timestamp.fromDate(
          dayjs().tz('UTC').hour(0).minute(0).second(0).toDate()
        )
      );
      const experiencesQuery = queryExperienceType
        ? query(
            experiencesRef,
            where('type', '==', queryExperienceType),
            fromNow
          )
        : query(experiencesRef, fromNow);

      const experiencesSnapshot = await getDocs(experiencesQuery);
      const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

      const experiencesData = [];
      for (let experienceSnapshot of experiencesSnapshot.docs) {
        if (!membersData[experienceSnapshot.data().memberId]) {
          membersData[experienceSnapshot.data().memberId] = (
            await getDoc(
              doc(getFirestore(), 'members', experienceSnapshot.data().memberId)
            )
          ).data();
        }

        // only consider processing this experience if the member has a complete profile
        if (isProfileReady(membersData[experienceSnapshot.data().memberId])) {
          /*
         If a start date was specified as a query parameter,
         and the start date of the experience is on the same day, then show the experience.
         If no start date was specified, show the experience irregardless of the start date of the experience.
         */
          if (
            (!Number.isNaN(queryStartDate) &&
              dayjs(experienceSnapshot.data().dates.start).isSame(
                queryStartDate,
                'day'
              )) ||
            Number.isNaN(queryStartDate)
          ) {
            /* 
          Assume the experience matches the search.
          Then if a location was provided, check if the current experience
          is in that area or close to it.
          */
            let experienceMatchesSearch = true;
            if (placeId) {
              const { latitude, longitude } = experienceSnapshot
                .data()
                .place.location.toJSON();
              if (placeDetails.geometry.bounds) {
                experienceMatchesSearch = placeDetails.geometry.bounds.contains(
                  {
                    lat: latitude,
                    lng: longitude
                  }
                );
              } else {
                const distance = computeDistance(
                  {
                    lat: latitude,
                    lng: longitude
                  },
                  placeDetails.geometry.location.toJSON()
                );
                // only show experiences within maxRadius (see above) kms of chosen destination
                experienceMatchesSearch = distance < maxRadius * 1000;
              }
            }

            /* 
          Only if this experience matches the search, then go and get
          the experience's image and details on the member running it.
          */
            if (experienceMatchesSearch) {
              let image;
              try {
                image = await getDownloadURL(
                  ref(
                    getStorage(),
                    `experiences/${experienceSnapshot.data().memberId}/${
                      experienceSnapshot.id
                    }_${maxImageDimension}x${maxImageDimension}.jpeg`
                  )
                );
              } catch (error) {
                image = 'https://picsum.photos/800';
              }

              experiencesData.push({
                ...experienceSnapshot.data(),
                id: experienceSnapshot.id,
                image
              });
            }
          }
        }
      }
      setMembers(membersData);
      setExperiences(experiencesData);
    };

    if (!placeId || (placeId && placeDetails)) {
      getExperiences();
    }
  }, [queryStartDate, queryExperienceType, placeId, placeDetails]);

  if (!experiences || !members) {
    return (
      <Grid container justifyContent="center">
        <Loader status="Searching experiences..." />
      </Grid>
    );
  }

  const searchResultItem = experience => (
    <Grid
      container
      direction={xsScreen ? 'column-reverse' : 'row'}
      key={experience.id}
    >
      <Grid
        item
        sm={12}
        md={7}
        style={{
          padding: 11,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>
          {experience.title}
        </Typography>
        <div style={{ display: 'flex', marginBottom: 8 }}>
          <Event style={{ marginRight: 8 }} />
          <Typography variant="body1">
            {dayjs(experience.dates.start).format('DD MMM YYYY')} (
            {experienceDuration(
              dayjs(experience.dates.start),
              dayjs(experience.dates.end)
            )}
            )
          </Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: 8 }}>
          <Person style={{ marginRight: 8 }} />
          <Typography variant="body1">
            {members[experience.memberId].firstName}{' '}
            {members[experience.memberId].lastName}
          </Typography>
        </div>
        <div style={{ display: 'flex', marginBottom: 8 }}>
          <LocationOn style={{ marginRight: 8 }} />
          <Typography variant="body1">{experience.place.address}</Typography>
        </div>
        <Typography variant="body1" style={{ flex: 1 }} gutterBottom>
          {experience.description}
        </Typography>
        <Typography variant="body1" style={{ fontStyle: 'italic' }}>
          {experience.participants.max - experience.participants.enrolled}{' '}
          {experience.participants.max - experience.participants.enrolled === 1
            ? 'spot'
            : 'spots'}{' '}
          left
        </Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 'bolder' }}>
          ${experience.price}/person
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={
            experience.participants.max === experience.participants.enrolled
          }
          style={{ marginTop: 22 }}
          onClick={async () =>
            navigate(`/checkout/experience/${experience.id}`)
          }
        >
          {experience.participants.max === experience.participants.enrolled
            ? 'Fully booked'
            : 'Book now'}
        </Button>
      </Grid>
      <Grid item sm={12} md={5} style={{ padding: 11 }}>
        <img
          src={experience.image}
          alt={experience.title}
          style={{ width: '100%', borderRadius: 11 }}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 11, marginBottom: 11 }}>
        <Divider />
      </Grid>
    </Grid>
  );

  let experiencesSection;
  if (experiences.length === 0) {
    // dolphin source: https://lottiefiles.com/23687-an-eagles-eye
    experiencesSection = (
      <Paper>
        <Lottie
          loop
          animationData={require('../lottie/dolphin-ocean.json')}
          play
        />
        <div style={{ padding: 11 }}>
          <Typography variant="subtitle2" gutterBottom>
            Mmm... no experiences were found
            {queryStartDate
              ? ` on ${dayjs(queryStartDate).format('D MMMM YYYY')}`
              : ''}
            {placeId ? ` in ${placeDetails.formatted_address}` : ''}.
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              if (queryStartDate) {
                setExperiences(undefined);
                navigate(`/results?placeId=${placeId}`, { replace: true });
              } else {
                navigate('/', { replace: true });
              }
            }}
          >
            {queryStartDate ? 'expand to all dates' : 'start over'}
          </Button>
        </div>
      </Paper>
    );
  } else if (!Number.isNaN(queryStartDate)) {
    experiencesSection = (
      <>
        <Typography variant={xsScreen ? 'h6' : 'h4'} gutterBottom>
          Experiences on {dayjs(queryStartDate).format('D MMMM YYYY')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Experiences found: {experiences.length}
        </Typography>
        {!xsScreen && <Divider style={{ marginTop: 22, marginBottom: 11 }} />}
      </>
    );
  } else {
    experiencesSection = (
      <>
        <Typography variant={xsScreen ? 'h6' : 'h4'}>
          All experiences
        </Typography>
        <Typography variant="subtitle1" gutterBottom color="textSecondary">
          {placeDetails
            ? placeDetails.geometry.bounds
              ? `within ${placeDetails.formatted_address}`
              : `within ${maxRadius} kms of ${placeDetails.formatted_address}`
            : `All experiences for ${queryExperienceType}`}
        </Typography>
        {!xsScreen && <Divider style={{ marginTop: 22, marginBottom: 11 }} />}
      </>
    );
  }

  let mapUrl = `https://maps.googleapis.com/maps/api/staticmap?maptype=terrain&size=480x640&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  if (experiences.length === 0) {
    mapUrl = placeId
      ? `${mapUrl}&center=${placeDetails.formatted_address}&zoom=5`
      : `${mapUrl}&center=Africa&zoom=1`;
  } else {
    const locations = experiences.map(experience => {
      const { latitude, longitude } = experience.place.location.toJSON();
      return `${latitude},${longitude}`;
    });
    mapUrl = `${mapUrl}&markers=${locations.join('|')}`;
  }
  mapUrl = encodeURI(mapUrl);

  return (
    <Fragment>
      <HeaderBar />
      <Grid container style={{ marginTop: 77, minHeight: '100vh' }}>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            padding: xsScreen ? 11 : 88
          }}
        >
          {experiencesSection}
          {experiences.map(experience => searchResultItem(experience))}
        </Grid>
        <Hidden smDown>
          <Grid
            item
            md={4}
            style={{ marginTop: 11, position: 'fixed', right: 11, zIndex: -1 }}
          >
            {placeDetails && (
              <Typography variant="caption">
                {placeDetails.formatted_address}
              </Typography>
            )}
            <img
              src={mapUrl}
              alt="Map of members"
              style={{ width: '100%', borderRadius: 8 }}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Footer />
    </Fragment>
  );
}
