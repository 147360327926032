import React, { useState } from 'react';
import {
  Grid,
  Avatar,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  Stack,
  FormControlLabel,
  FormGroup,
  Checkbox
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { CloudUpload } from '@mui/icons-material';
import { doc, getFirestore, updateDoc, Timestamp } from 'firebase/firestore';
import GooglePlaces from './GoogleMaps';

const minimumParticipants = 1;
const minimumExperiencePrice = 3;

const ExperienceData = props => {
  const {
    uid,
    showExperienceDialog,
    dialogTitle,
    onSave,
    closeDialog,
    imageDimension,
    initialExperienceImage,
    initialTitle = '',
    initialStartDate = dayjs().add(1, 'week').toDate(),
    initialEndDate = dayjs().add(1, 'week').toDate(),
    initialDescription = '',
    initialPlace = '',
    initialPrerequisites = '',
    initialMaxParticipants = '',
    initialType = '',
    initialPrice = '',
    initialNonrefundableDeposit = { required: false, amount: 0 }
  } = props;

  // experiences data
  const [experienceImage, setExperienceImage] = useState(
    initialExperienceImage
  );
  const [title, setTitle] = useState(initialTitle);
  const [place, setPlace] = useState(
    initialPlace
      ? { place_id: initialPlace.id, address: initialPlace.address }
      : {}
  );
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [description, setDescription] = useState(initialDescription);
  const [prerequisites, setPrerequisites] = useState(initialPrerequisites);
  const [maxParticipants, setMaxParticipants] = useState(
    initialMaxParticipants
  );
  const [type, setType] = useState(initialType);
  const [price, setPrice] = useState(initialPrice);
  const [nonrefundableDeposit, setNonrefundableDeposit] = useState(
    initialNonrefundableDeposit
  );
  const [error, setError] = useState({});

  return (
    <Dialog
      fullScreen
      open={showExperienceDialog}
      onClose={(evt, reason) => {
        console.log('closing...', reason);
        closeDialog();
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} style={{ marginTop: 22, padding: 11 }}>
            <Avatar
              alt={title}
              variant="rounded"
              style={{ width: '100%', height: '100%', marginBottom: 11 }}
              src={
                experienceImage
                  ? typeof experienceImage === 'string' &&
                    experienceImage.startsWith('https')
                    ? experienceImage
                    : URL.createObjectURL(experienceImage)
                  : `https://picsum.photos/${imageDimension}`
              }
            />
            <>
              <input
                color="primary"
                accept="image/*"
                type="file"
                onChange={async event => {
                  setExperienceImage(event.target.files[0]);
                }}
                id="icon-button-file"
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  size="small"
                  startIcon={<CloudUpload />}
                >
                  Upload photo
                </Button>
              </label>
            </>
          </Grid>
          <Grid item xs={12} md={6} style={{ marginTop: 22, padding: 11 }}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              style={{ marginBottom: 11, marginTop: 11 }}
              onChange={evt => setTitle(evt.target.value)}
            />
            <Stack spacing={3} direction="row">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // clearable
                  // inputFormat="DD MMM YYYY"
                  renderInput={params => <TextField {...params} />}
                  // inputVariant="outlined"
                  // style={{ marginBottom: 11, marginTop: 11, marginRight: 11 }}
                  label="When does the experience begin?"
                  value={startDate}
                  onChange={date => {
                    setStartDate(date);
                    setEndDate(date);
                  }}
                  minDate={new Date()}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // clearable
                  // inputFormat="DD MMM YYYY"
                  renderInput={params => <TextField {...params} />}
                  // inputVariant="outlined"
                  // style={{ marginBottom: 11, marginTop: 11 }}
                  label="When does the experience end?"
                  value={endDate}
                  onChange={date => setEndDate(date)}
                  minDate={startDate}
                />
              </LocalizationProvider>
            </Stack>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: 11, marginTop: 11 }}
            >
              <InputLabel id="experience-label">Experience Type</InputLabel>
              <Select
                labelId="experience-label"
                value={type}
                onChange={event => setType(event.target.value)}
                label="Experience Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'scuba-diving'}>Scuba Diving</MenuItem>
                <MenuItem value={'freediving'}>Freediving</MenuItem>
                <MenuItem value={'photography'}>Photography</MenuItem>
                <MenuItem value={'boat-hire'}>Boat Hire</MenuItem>
              </Select>
            </FormControl>
            <GooglePlaces
              label="Where is your experience being held?"
              setValue={setPlace}
              value={place}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
              value={description}
              style={{ marginBottom: 11, marginTop: 11 }}
              onChange={evt => setDescription(evt.target.value)}
            />
            <TextField
              label="Prerequisites (optional)"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              value={prerequisites}
              style={{ marginBottom: 11, marginTop: 11 }}
              onChange={evt => setPrerequisites(evt.target.value)}
            />
            <TextField
              label="Max participants"
              variant="outlined"
              fullWidth
              error={!!error.participants}
              helperText={error.participants}
              value={maxParticipants}
              type="number"
              style={{ marginBottom: 11 }}
              onChange={evt => {
                setError({ participants: undefined });
                setMaxParticipants(evt.target.value);

                if (
                  evt.target.value !== '' &&
                  parseInt(evt.target.value) < minimumParticipants
                ) {
                  setError({
                    participants: `Minimum of ${minimumParticipants} participant required.`
                  });
                }
              }}
            />
            <TextField
              label="Total price of experience"
              variant="outlined"
              fullWidth
              error={!!error.price}
              helperText={error.price}
              value={price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              type="number"
              style={{ marginBottom: 11 }}
              onChange={evt => {
                setError({ price: undefined });

                setPrice(evt.target.value);
                if (
                  evt.target.value !== '' &&
                  parseFloat(evt.target.value) < minimumExperiencePrice
                ) {
                  setError({
                    price: `Minimum of $${minimumExperiencePrice} required.`
                  });
                }
              }}
            />
            <Stack direction="row">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nonrefundableDeposit.required}
                      onChange={event =>
                        setNonrefundableDeposit({
                          ...nonrefundableDeposit,
                          required: event.target.checked
                        })
                      }
                    />
                  }
                  label="Nonrefundable deposit required?"
                />
              </FormGroup>
              <TextField
                label="Deposit amount"
                variant="outlined"
                disabled={!nonrefundableDeposit.required}
                fullWidth
                error={!!error.deposit}
                helperText={error.deposit}
                value={nonrefundableDeposit.amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
                type="number"
                style={{ marginBottom: 11 }}
                onChange={evt => {
                  setError({ deposit: undefined });

                  setNonrefundableDeposit({
                    ...nonrefundableDeposit,
                    amount: parseFloat(evt.target.value)
                  });
                  if (
                    evt.target.value !== '' &&
                    parseFloat(evt.target.value) > price
                  ) {
                    setError({
                      deposit: `Deposit can't be greater than the cost of the experience.`
                    });
                  }
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            closeDialog(true);
            await onSave({
              title,
              description,
              placeId: place.place_id,
              prerequisites,
              startDate: dayjs(startDate),
              endDate: dayjs(endDate),
              price,
              maxParticipants,
              experienceImage,
              type,
              nonrefundableDeposit
            });

            await updateDoc(doc(getFirestore(), 'members', uid), {
              lastUpdated: Timestamp.now()
            });
          }}
          color="primary"
          disabled={
            !title ||
            !description ||
            !price ||
            Object.keys(place).length === 0 ||
            parseFloat(price) < minimumExperiencePrice ||
            !maxParticipants ||
            parseFloat(maxParticipants) < minimumParticipants ||
            !type
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExperienceData;
