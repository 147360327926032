import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  ImageList,
  ImageListItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Snackbar,
  Link
} from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { httpsCallable, getFunctions } from 'firebase/functions';
import {
  addDoc,
  doc,
  getFirestore,
  collection,
  getDoc,
  Timestamp
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, listAll } from 'firebase/storage';
import Loader from '../Loader';
import GooglePlaces from '../GoogleMaps';

const SynergyDivingProduct = ({ productId, variantId }) => {
  const stripe = useStripe();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [postalAddress, setPostalAddress] = useState();
  const [product, setProduct] = useState();
  const [confirmedTerms, setConfirmedTerms] = useState(false);
  const [snackbar, setSnackbar] = useState();
  const [marketing, setMarketing] = useState(false);

  useEffect(() => {
    const getProduct = async () => {
      const productSnapshot = await getDoc(
        doc(getFirestore(), 'products/synergy-diving/merch', productId)
      );

      const productImagesRef = await listAll(
        ref(getStorage(), `products/synergy-diving/${productId}`)
      );
      const productImages = [];
      for (let productImage of productImagesRef.items) {
        const imageUrl = await getDownloadURL(productImage);
        productImages.push(imageUrl);
      }
      const variantSnapshot = await getDoc(
        doc(
          getFirestore(),
          'products/synergy-diving/merch',
          productId,
          'variants',
          variantId
        )
      );

      setProduct({
        ...productSnapshot.data(),
        id: productSnapshot.id,
        variant: variantSnapshot.data(),
        productImages
      });
    };

    getProduct();
  }, [productId, variantId]);

  if (!product) {
    return <Loader status="Loading your selection..." />;
  }

  return (
    <Grid container justifyContent="center" sx={{ p: 1 }}>
      <Grid item xs={12} sx={{ p: 3 }}>
        <Typography variant="h4">Checkout 🐳</Typography>
      </Grid>

      <Grid item xs={12} md={6} sx={{ mb: 1, p: 1 }}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom>
            {product.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {product.description}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Cost: ${product.variant.price} ({product.variant.title}) and free
            shipping included!
          </Typography>
          <ImageList cols={2}>
            {product.productImages.map(img => (
              <ImageListItem key={img}>
                <img
                  src={`${img}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={product.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Paper>
      </Grid>

      <Grid item md={6} sx={{ p: 1 }}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={3}>
            <Typography variant="h6" gutterBottom>
              We just need a few details
            </Typography>
            <TextField
              label="First name"
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={evt => setFirstName(evt.target.value)}
            />
            <TextField
              label="Last name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={evt => setLastName(evt.target.value)}
            />
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={evt => setEmail(evt.target.value)}
            />
            <GooglePlaces
              label="Type in your shipping address"
              setValue={setPostalAddress}
              country={['us']}
              helperText="We currently only ship to the USA."
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={confirmedTerms}
                    onChange={() => setConfirmedTerms(!confirmedTerms)}
                  />
                }
                label="I agree to Synergy Diving's terms and conditions, and privacy policy"
              />
              <Typography variant="caption">
                <ul>
                  <li>
                    <Link
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy" target="_blank" rel="noreferrer">
                      Privacy policy
                    </Link>
                  </li>
                </ul>
              </Typography>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketing}
                    onChange={() => setMarketing(!marketing)}
                  />
                }
                label="Shell yeah, I’d like to receive promotional updates from Synergy Diving"
              />
            </div>
            <Button
              variant="contained"
              disabled={
                firstName.trim() === '' ||
                lastName.trim() === '' ||
                email.trim() === '' ||
                !postalAddress ||
                !confirmedTerms
              }
              color="primary"
              onClick={async () => {
                setSnackbar('Setting up payment on Synergy Diving...');
                const res = await addDoc(
                  collection(getFirestore(), 'payments'),
                  {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: email.trim(),
                    synergyDivingProductId: productId,
                    variantId,
                    place: {
                      id: postalAddress.place_id,
                      address: postalAddress.description
                    },
                    initiatedAt: Timestamp.now()
                  }
                );

                const createCheckoutSession = httpsCallable(
                  getFunctions(),
                  'createCheckoutSession'
                );
                const session = await createCheckoutSession({
                  synergyDivingMerch: product,
                  paymentId: res.id
                });

                if (marketing) {
                  const marketingAdd = httpsCallable(
                    getFunctions(),
                    'marketingAdd'
                  );
                  await marketingAdd({
                    firstName,
                    lastName,
                    email,
                    paymentId: res.id
                  });
                }

                await stripe.redirectToCheckout({
                  sessionId: session.data.id
                });
              }}
            >
              Continue to payment
            </Button>
          </Stack>
        </Paper>
      </Grid>

      <Snackbar
        open={!!snackbar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        // autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar(undefined)}
        message={snackbar}
      />
    </Grid>
  );
};

export default SynergyDivingProduct;
