import React from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button,
  Divider,
  useMediaQuery
} from '@mui/material';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import backgroundImage from '../images/freediving-with-fish.jpeg';
import hiWetsuits from '../images/partners/hawaii-wetsuits.webp';
import parrotFish from '../images/partners/parrot-fish.png';
import parrotFishBlack from '../images/partners/parrot-fish-black.png';

export default function Promos() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 11,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginTop: 33
        }}
      >
        <Grid item md={8} lg={8}>
          <Paper
            style={{
              marginTop: 88,
              padding: 22,
              marginBottom: 33
            }}
          >
            <Typography variant="h5" gutterBottom>
              Synergy Diving partnerships & promotions
            </Typography>
            <hr />
            <Typography variant="h4" gutterBottom>
              HI Wetsuits
            </Typography>
            <img src={hiWetsuits} alt="HI Wetsuits" />
            <Typography variant="body1" gutterBottom>
              Hawaii’s leading manufacturer of custom freediving, spearfishing,
              and surfing wetsuits.
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              style={{ display: 'block' }}
            >
              10% off discount code: Synergy Diving
            </Typography>
            <Button variant="outlined" href="https://hiwetsuits.com/">
              Order
            </Button>
            <Divider style={{ margin: 33 }} />
            <Typography variant="h4" gutterBottom>
              Parrotfish Sports Swimwear
            </Typography>
            <img
              src={prefersDarkMode ? parrotFish : parrotFishBlack}
              alt="HI Wetsuits"
              width="88%"
            />
            <Typography variant="body1" gutterBottom>
              Beautiful, sustainable swimwear designed for the divers, surfers
              and water sport lovers who care about protecting the oceans we
              play in. Swimwear is certifiably made from plastic bottles.
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              style={{ display: 'block' }}
            >
              20% off discount code: SynergyDiving20
            </Typography>
            <Button variant="outlined" href="https://parrotfish.io/">
              Order
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
