import { Grid, Paper, Typography, LinearProgress } from '@mui/material';
import { getLogo, getFeelGood } from '../modules/utils';

const Loader = ({ status }) => (
  <Grid container justifyContent="center">
    <Paper
      style={{
        padding: 33,
        textAlign: 'center',
        marginTop: 111,
        minWidth: 333
      }}
    >
      {getLogo() && (
        <img src={getLogo()} alt="Synergy Diving logo" style={{ width: 111 }} />
      )}
      <Typography variant="body1" style={{ marginBottom: 11 }}>
        {getFeelGood()}
      </Typography>
      <LinearProgress />
    </Paper>
  </Grid>
);

export default Loader;
