import { useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slider,
  Box
} from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { CloudUpload } from '@mui/icons-material';

const ImageEditor = ({
  editImage,
  setEditImage,
  editedImage,
  initialImage
}) => {
  const [imageFile, setImageFile] = useState(initialImage);
  const [scale, setScale] = useState(1);
  const setEditorRef = useRef();

  const saveImage = () => {
    if (setEditorRef) {
      const canvas = setEditorRef.current.getImage();
      canvas.toBlob(blob => editedImage(blob));
      setEditImage(false);
    }
  };

  const editorDimenson = 272;

  return (
    <Dialog open={editImage} onClose={() => setEditImage(false)}>
      <DialogTitle>Profile image</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          Choose your profile image
        </DialogContentText>

        <AvatarEditor
          ref={setEditorRef}
          image={imageFile}
          width={editorDimenson}
          height={editorDimenson}
          border={8}
          color={[0, 0, 0, 0.8]}
          scale={scale}
          rotate={0}
        />
        <Box>
          <input
            color="primary"
            accept="image/*"
            type="file"
            onChange={event => setImageFile(event.target.files[0])}
            id="icon-button-file"
            hidden
          />
          <label htmlFor="icon-button-file">
            <Button
              variant="outlined"
              component="span"
              size="small"
              startIcon={<CloudUpload />}
            >
              Select profile photo
            </Button>
          </label>
        </Box>

        <Slider
          aria-label="Volume"
          value={scale}
          step={0.1}
          min={1}
          max={3}
          onChange={(evt, newValue) => setScale(newValue)}
        />
        <Button onClick={() => saveImage()} variant="contained">
          Update
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ImageEditor;
