import { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  Avatar,
  IconButton
} from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, getStorage, getDownloadURL } from 'firebase/storage';
import { Link, useNavigate } from 'react-router-dom';
import diverLogo from '../images/synergy-diving-logo-white.png';
import { getFirestore, onSnapshot, doc } from 'firebase/firestore';

const imageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

const HeaderBar = () => {
  const navigate = useNavigate();
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [anchorEl, setAnchorEl] = useState();
  useEffect(() => {
    const watchProfilePhoto = async () => {
      onSnapshot(
        doc(getFirestore(), 'members', getAuth().currentUser.uid),
        async loggedInUser => {
          let url;
          try {
            url = await getDownloadURL(
              ref(
                getStorage(),
                `members/${loggedInUser.id}/profile_${imageDimension}x${imageDimension}.jpeg`
              )
            );
            setProfileImageUrl(url);
          } catch (error) {
            setProfileImageUrl(`https://picsum.photos/${imageDimension}`);
          }
        }
      );
    };

    const unsub = onAuthStateChanged(getAuth(), user => {
      if (user) {
        watchProfilePhoto();
      }
    });

    if (getAuth().currentUser) {
      watchProfilePhoto();
    }
    // TODO: also unsubscribe onsnapshot on watching
    return () => unsub();
  }, []);

  return (
    <Box flexGrow={1}>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <Link
            to="/"
            style={{
              display: 'flex',
              textDecoration: 'none',
              color: 'white',
              flexGrow: 1
            }}
          >
            <img
              src={diverLogo}
              alt="Synergy Diving logo"
              style={{
                width: 33,
                height: 33,
                marginRight: 11
              }}
            />
            <Typography variant="h6">Synergy Diving</Typography>
          </Link>
          {profileImageUrl ? (
            <>
              <IconButton
                onClick={event => setAnchorEl(event.currentTarget)}
                size="large"
              >
                <Avatar src={profileImageUrl} />
              </IconButton>
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() =>
                    navigate('/member/dashboard', { replace: true })
                  }
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    getAuth().signOut();
                    navigate('/member', { replace: true });
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => navigate('/member/login')}
            >
              login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HeaderBar;
