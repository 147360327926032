import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  CardContent,
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Snackbar,
  DialogActions,
  Stack,
  AlertTitle,
  Link,
  Box
} from '@mui/material';
import {
  VerifiedUser,
  Share,
  LocationOn,
  AttachMoney,
  Event
} from '@mui/icons-material';
import { Alert } from '@mui/lab';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { httpsCallable, getFunctions } from 'firebase/functions';
import {
  addDoc,
  doc,
  getFirestore,
  collection,
  getDoc,
  getDocs,
  Timestamp
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import Loader from '../Loader';
import { experienceDuration, generateParagraphs } from '../../modules/utils';

dayjs.extend(objectSupport);

const Experience = ({ experienceId }) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [inquireName, setInquireName] = useState('');
  const [inquireEmail, setInquireEmail] = useState('');
  const [inquireQuestion, setInquireQuestion] = useState('');
  const [experience, setExperience] = useState();
  const [member, setMember] = useState();
  const [confirmedPrerequisites, setConfirmedPrerequisites] = useState();
  const [confirmedTerms, setConfirmedTerms] = useState(false);
  const [processing, setProcessing] = useState('Initialising...');
  const [showMember, setShowMember] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [error, setError] = useState();

  useEffect(() => {
    const getExperience = async () => {
      setProcessing('Loading experience...');
      const experienceSnapshot = await getDoc(
        doc(getFirestore(), 'experiences', experienceId)
      );

      if (
        experienceSnapshot.data().participants.max ===
        experienceSnapshot.data().participants.enrolled
      ) {
        setError('This experience is now fully booked.');
        return;
      }

      if (experienceSnapshot.data().prerequisites) {
        setConfirmedPrerequisites(false);
      }

      setProcessing('Loading member data...');
      const { firstName, lastName, bio } = (
        await getDoc(
          doc(getFirestore(), 'members', experienceSnapshot.data().memberId)
        )
      ).data();

      setProcessing('Checking for verifications...');
      const verificationsSnapshot = await getDocs(
        collection(
          getFirestore(),
          'members',
          experienceSnapshot.data().memberId,
          'verifications'
        )
      );

      const verificationData = {};
      for (const verficationSnapshot of verificationsSnapshot.docs) {
        if (verficationSnapshot.data().verified) {
          verificationData[verficationSnapshot.id] = verficationSnapshot.data();
        }
      }

      const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;
      setProcessing('Loading member image...');
      let memberImage;
      try {
        memberImage = await getDownloadURL(
          ref(
            getStorage(),
            `members/${
              experienceSnapshot.data().memberId
            }/profile_${maxImageDimension}x${maxImageDimension}.jpeg`
          )
        );
      } catch (error) {
        console.log(error.code);
        memberImage = `https://picsum.photos/${maxImageDimension}`;
      }

      let image;
      try {
        image = await getDownloadURL(
          ref(
            getStorage(),
            `experiences/${experienceSnapshot.data().memberId}/${
              experienceSnapshot.id
            }_${maxImageDimension}x${maxImageDimension}.jpeg`
          )
        );
      } catch (error) {
        image = 'https://picsum.photos/800';
      }

      setExperience({
        ...experienceSnapshot.data(),
        image,
        id: experienceSnapshot.id
      });
      setMember({
        image: memberImage,
        name: `${firstName} ${lastName}`,
        bio,
        verifications: verificationData
      });
      setProcessing('');
    };

    getExperience();
  }, [experienceId]);

  if (error) {
    return (
      <Grid container justifyContent="center">
        <Grid
          item
          style={{
            marginTop: 55,
            maxWidth: 555,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Lottie
            loop
            animationData={require('../../lottie/space-panda-and-turtle.json')}
            play
          />
          <Typography style={{ padding: 11 }} variant="body1" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/', { replace: true })}
          >
            Return to Synergy Diving
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (processing !== '') {
    return <Loader status={processing} />;
  }

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          p: 1
        }}
      >
        <Grid item xs={12} sx={{ p: 3 }}>
          <Grid container>
            <Grid item sm={8}>
              <Typography variant="h4" gutterBottom>
                Book an experience
              </Typography>
            </Grid>
            <Grid item sm={4} sx={{ textAlign: 'right' }}>
              <Button
                startIcon={<Share />}
                variant="outlined"
                size="small"
                onClick={async () => {
                  await navigator.clipboard.writeText(window.location.href);
                  setSnackbar({
                    open: true,
                    message: `"${experience.title}" experience link copied to clipboard`,
                    duration: 5500
                  });
                }}
              >
                Share
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} sx={{ mb: 3, padding: 1 }}>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  src={member.image}
                  onClick={() => setShowMember(true)}
                />
              }
              title={experience.title}
              subheader={`with ${member.name}`}
            />
            <CardMedia
              image={experience.image}
              title={experience.title}
              sx={{ height: 555 }}
            />
            <CardContent>
              {generateParagraphs(experience.description)}
              <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                <Event color="secondary" sx={{ mr: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  {`${dayjs(experience.dates.start).format(
                    'DD MMM YYYY'
                  )} - ${dayjs(experience.dates.end).format(
                    'DD MMM YYYY'
                  )} (${experienceDuration(
                    dayjs(experience.dates.start),
                    dayjs(experience.dates.end)
                  )})`}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <LocationOn color="secondary" sx={{ mr: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  {experience.place.address}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <AttachMoney color="secondary" sx={{ mr: 1 }} />
                <Typography variant="body2" color="textSecondary">
                  {experience.price}
                </Typography>
              </Box>

              <Button
                size="small"
                sx={{ my: 2 }}
                variant="outlined"
                onClick={() => setShowMember(true)}
              >
                About {member.name}
              </Button>

              <Alert severity="info" variant="outlined" sx={{ mt: 3 }}>
                <AlertTitle>
                  {experience.nonrefundableDeposit &&
                  experience.nonrefundableDeposit.required
                    ? `This experience has a nonrefundable deposit of $
                  ${experience.nonrefundableDeposit.amount}`
                    : 'This experience is fully refundable.'}
                </AlertTitle>
                If you cancel 72 hours before the start of this experience,
                you'll receive back the cost of this experience ($
                {experience.price})
                {experience.nonrefundableDeposit &&
                experience.nonrefundableDeposit.required
                  ? ` less the nonrefundable deposit ($${experience.nonrefundableDeposit.amount}), which would be $`
                  : ''}
                {experience.nonrefundableDeposit &&
                experience.nonrefundableDeposit.required
                  ? experience.price - experience.nonrefundableDeposit.amount
                  : ''}
                .
              </Alert>
            </CardContent>
          </Card>
          <Dialog
            open={showMember}
            onClose={() => setShowMember(false)}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogContent>
              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} md={6}>
                  <Avatar
                    src={member.image}
                    style={{
                      height: 222,
                      width: 222,
                      marginBottom: 22
                    }}
                    variant="rounded"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    {member.name}
                  </Typography>

                  {Object.keys(member.verifications).map(verificationId => (
                    <Typography
                      variant="body1"
                      key={verificationId}
                      style={{
                        display: 'flex'
                      }}
                      gutterBottom
                    >
                      <VerifiedUser
                        style={{
                          marginRight: 11
                        }}
                      />{' '}
                      {member.verifications[verificationId].assertion}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {generateParagraphs(member.bio)}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowMember(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item md={6} xs={12} sx={{ padding: 1 }}>
          <Paper
            style={{
              padding: 22
            }}
          >
            <Stack spacing={3}>
              <Typography variant="h6" gutterBottom>
                We just need a few details
              </Typography>
              <TextField
                label="First name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={evt => setFirstName(evt.target.value)}
              />
              <TextField
                label="Last name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={evt => setLastName(evt.target.value)}
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />
              {experience.prerequisites && (
                <div>
                  <TextField
                    disabled
                    multiline
                    label="Prerequisites"
                    variant="outlined"
                    fullWidth
                    value={experience.prerequisites}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmedPrerequisites}
                        onChange={() =>
                          setConfirmedPrerequisites(!confirmedPrerequisites)
                        }
                      />
                    }
                    label="I agree to the above prerequisites"
                  />
                </div>
              )}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmedTerms}
                      onChange={() => setConfirmedTerms(!confirmedTerms)}
                    />
                  }
                  label="I agree to Synergy Diving's terms and conditions, and privacy policy"
                />
                <Typography variant="caption">
                  <ul>
                    <li>
                      <Link
                        href="/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link href="/privacy" target="_blank" rel="noreferrer">
                        Privacy policy
                      </Link>
                    </li>
                  </ul>
                </Typography>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={marketing}
                      onChange={() => setMarketing(!marketing)}
                    />
                  }
                  label="Shell yeah, I’d like to receive promotional updates from Synergy Diving"
                />
              </div>
              <Button
                variant="contained"
                disabled={
                  firstName.trim() === '' ||
                  lastName.trim() === '' ||
                  email.trim() === '' ||
                  (experience.prerequisites && !confirmedPrerequisites) ||
                  !confirmedTerms
                }
                color="primary"
                onClick={async () => {
                  setProcessing('Setting up payment on Synergy Diving...');
                  const res = await addDoc(
                    collection(getFirestore(), 'payments'),
                    {
                      firstName: firstName.trim(),
                      lastName: lastName.trim(),
                      email: email.trim(),
                      experienceId: experience.id,
                      initiatedAt: Timestamp.now()
                    }
                  );

                  const createCheckoutSession = httpsCallable(
                    getFunctions(),
                    'createCheckoutSession'
                  );
                  const session = await createCheckoutSession({
                    experience,
                    paymentId: res.id
                  });

                  if (marketing) {
                    const marketingAdd = httpsCallable(
                      getFunctions(),
                      'marketingAdd'
                    );
                    await marketingAdd({
                      firstName,
                      lastName,
                      email,
                      paymentId: res.id
                    });
                  }

                  await stripe.redirectToCheckout({
                    sessionId: session.data.id
                  });
                }}
              >
                Continue to payment
              </Button>
            </Stack>
          </Paper>
          <Paper
            style={{
              padding: 22,
              marginTop: 22
            }}
          >
            {emailSent && (
              <Alert severity="info">
                Thanks {inquireName}. Your email has been sent.
              </Alert>
            )}
            {!emailSent && (
              <Stack spacing={3}>
                <Typography variant="body1" gutterBottom>
                  Want to ask {member.name} a question before you book?
                </Typography>
                <TextField
                  label="Your name"
                  variant="outlined"
                  fullWidth
                  value={inquireName}
                  onChange={evt => setInquireName(evt.target.value)}
                />
                <TextField
                  label="Your email address"
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={inquireEmail}
                  onChange={evt => setInquireEmail(evt.target.value)}
                />
                <TextField
                  label="Question"
                  multiline
                  minRows={8}
                  variant="outlined"
                  fullWidth
                  value={inquireQuestion}
                  onChange={evt => setInquireQuestion(evt.target.value)}
                />
                <Button
                  variant="contained"
                  disabled={
                    inquireName.trim() === '' ||
                    inquireEmail.trim() === '' ||
                    inquireQuestion.trim() === ''
                  }
                  color="primary"
                  onClick={async () => {
                    setSnackbar({
                      open: true,
                      message: 'Delivering your email now...',
                      duration: 5500
                    });
                    // email message to us
                    const sendEmail = httpsCallable(
                      getFunctions(),
                      'sendEmail'
                    );

                    setEmailSent(true);
                    let res = await sendEmail({
                      to: 'hello@synergydiving.com',
                      subject: `Inquiry from ${inquireName}`,
                      body: `<p>Hi team!</p>
        <p>We just got an inquiry from ${inquireName} (${inquireEmail}) for "${
                        experience.title
                      }" with ${member.name} starting ${dayjs(
                        experience.dates.start
                      ).format('DD MMM YYYY')} (ID: ${experienceId})</p>
                        <p>Their message is:</p>
                        <blockquote><pre>${inquireQuestion}</pre></blockquote>`
                    });
                    console.log(res);
                    res = await sendEmail({
                      to: inquireEmail,
                      subject: `Thank you for your inquiry`,
                      body: `<p>Hi ${inquireName}!</p>
                        <p>Thank you for your inquiry for "${
                          experience.title
                        }" with ${member.name} starting ${dayjs(
                        experience.dates.start
                      ).format('DD MMM YYYY')}.</p>
                        <p>Your message was:</p>
                        <blockquote><pre>${inquireQuestion}</pre></blockquote>`
                    });
                    console.log(res);
                  }}
                >
                  Send email
                </Button>
              </Stack>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
      />
    </>
  );
};

export default Experience;
