import { useEffect } from 'react';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Loader from '../components/Loader';

const RefreshOnboarding = () => {
  useEffect(() => {
    const unsub = onAuthStateChanged(getAuth(), async user => {
      if (user) {
        const createOnboardingLink = httpsCallable(
          getFunctions(),
          'createOnboardingLink'
        );
        console.log('refresh onboarding...');
        const url = await createOnboardingLink();
        console.log(url);
        window.location.href = url.data;
      }
    });

    return () => unsub();
  }, []);

  return <Loader status="Continuing with onboarding process..." />;
};

export default RefreshOnboarding;
