import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { isProfileReady } from '../modules/utils';

export default function Members() {
  const navigate = useNavigate();
  const [members, setMembers] = useState();

  useEffect(() => {
    const getMembers = async () => {
      const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

      const membersSnapshot = await getDocs(
        collection(getFirestore(), 'members')
      );
      const membersData = [];
      for (let memberSnapshot of membersSnapshot.docs) {
        if (isProfileReady(memberSnapshot.data())) {
          const image = await getDownloadURL(
            ref(
              getStorage(),
              `members/${memberSnapshot.id}/profile_${maxImageDimension}x${maxImageDimension}.jpeg`
            )
          );
          membersData.push({
            ...memberSnapshot.data(),
            image,
            id: memberSnapshot.id
          });
        }
      }

      membersData.sort((a, b) => a.lastName.localeCompare(b.lastName));
      setMembers(membersData);
    };

    getMembers();
  }, []);

  if (!members) {
    return (
      <Grid container justifyContent="center">
        <Loader status="Loading members..." />
      </Grid>
    );
  }

  const memberItem = member => (
    <Grid item xs={12} sm={6} md={4} style={{ padding: 33 }} key={member.id}>
      <Card>
        <CardHeader title={`${member.firstName} ${member.lastName}`} />
        <CardMedia
          image={member.image}
          title={`${member.firstName} ${member.lastName}`}
          style={{ height: 333 }}
        />
        <CardContent style={{ minHeight: 88 }}>
          <Typography variant="body2" color="textSecondary" component="p">
            {member.bio.substring(0, 333)}
            {member.bio.length > 333 ? '...' : ''}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/profiles/${member.id}`)}
          >
            view profile
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );

  return (
    <Fragment>
      <HeaderBar />
      <Grid container style={{ marginTop: 77, minHeight: '100vh' }}>
        <Grid item xs={12} style={{ padding: 33 }}>
          <Typography variant="h5">Say hello to our members!</Typography>
        </Grid>
        {members.map(member => memberItem(member))}
      </Grid>
      <Footer />
    </Fragment>
  );
}
