import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Paper,
  Alert,
  AlertTitle,
  Typography,
  Button,
  TextField,
  Box,
  Divider,
  useMediaQuery,
  Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { setDoc, doc, getFirestore, getDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import Loader from './Loader';
import logo from '../images/synergy-diving-logo.png';
import backgroundImage from '../images/turtle.jpeg';

const SignUp = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [referralMember, setReferralMember] = useState();
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});
  const [loadingMessage, setLoadingMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { referralMemberId } = useParams();

  useEffect(() => {
    const getMember = async () => {
      const member = await getDoc(
        doc(getFirestore(), 'members', referralMemberId)
      );
      setReferralMember(member.data());
    };

    getMember();
  }, [referralMemberId]);

  let content;
  if (processing) {
    content = <Loader status={loadingMessage} />;
  } else {
    content = (
      <Grid
        container
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          minHeight: '100vh'
        }}
      >
        <Grid item xs={12} md={9} lg={6}>
          <Paper
            sx={{
              padding: smScreen ? 2 : 3,
              marginTop: smScreen ? 2 : 5,
              marginLeft: smScreen ? 1 : 8,
              marginRight: smScreen ? 1 : 8,
              mb: 3
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 1,
                cursor: 'pointer'
              }}
              onClick={() => navigate('/', { replace: true })}
            >
              <img
                src={logo}
                alt="Synergy Diving logo"
                style={{ width: 44, marginRight: 11 }}
              />
              <Typography variant="h5">Synergy Diving</Typography>
            </Box>
            {referralMember && (
              <Alert severity="info" sx={{ mt: 3 }}>
                <AlertTitle>Valid referral code!</AlertTitle>
                <Typography variant="body2">
                  Awesome! Your referral member is{' '}
                  <strong>
                    {referralMember.firstName} {referralMember.lastName}
                  </strong>
                  .
                </Typography>
                <Typography variant="body2">
                  You'll both be receiving benefits for this connection :)
                </Typography>
              </Alert>
            )}
            <Alert severity="info" sx={{ mt: 3 }}>
              <AlertTitle>Accounts for instructors</AlertTitle>
              <Typography variant="body2" gutterBottom>
                If you're an instructor and want to join us, this is the place
                to start!
              </Typography>
              <Typography variant="body2" gutterBottom>
                If you're a course participant and have any questions, please{' '}
                <Link href="/contact">contact us</Link>.
              </Typography>
            </Alert>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 3, mb: 2 }}>
              Create your account
            </Typography>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { mb: 3 }
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                label="First name"
                variant="outlined"
                fullWidth
                autoFocus
                value={firstName}
                onChange={evt => setFirstName(evt.target.value)}
              />
              <TextField
                label="Last name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={evt => setLastName(evt.target.value)}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                error={!!error.email}
                helperText={error.email}
                value={email}
                onChange={evt => {
                  if (error.email) {
                    const newState = { ...error };
                    delete newState.email;
                    setError(newState);
                  }
                  setEmail(evt.target.value);
                }}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                error={!!error.password}
                helperText={error.password}
                type="password"
                value={password}
                onChange={evt => {
                  if (error.password) {
                    const newState = { ...error };
                    delete newState.password;
                    setError(newState);
                  }
                  setPassword(evt.target.value);
                }}
              />
              <Button
                variant="contained"
                disabled={
                  firstName === '' ||
                  lastName === '' ||
                  email === '' ||
                  password === '' ||
                  Object.keys(error).length > 0
                }
                color="primary"
                onClick={async () => {
                  const createAccount = async (email, password) => {
                    return new Promise((resolve, reject) => {
                      createUserWithEmailAndPassword(getAuth(), email, password)
                        .then(userCreds => resolve(userCreds))
                        .catch(reason => reject(reason));
                    });
                  };
                  try {
                    setProcessing(true);
                    setLoadingMessage(
                      'Creating new account on Synergy Diving...'
                    );
                    const memberCredential = await createAccount(
                      email,
                      password
                    );
                    setLoadingMessage('Saving profile information...');
                    await setDoc(
                      doc(getFirestore(), 'members', memberCredential.user.uid),
                      {
                        firstName: firstName.trim(),
                        lastName: lastName.trim()
                      }
                    );

                    // if we have a referral member ID in the sign up URL..
                    if (referralMember) {
                      const recordReferral = httpsCallable(
                        getFunctions(),
                        'recordReferral'
                      );

                      await recordReferral({
                        referredByMemberId: referralMemberId,
                        newMemberId: memberCredential.user.uid
                      });
                    }
                    navigate('/member/dashboard', { replace: true });
                  } catch (error) {
                    setProcessing(false);
                    if (error.code === 'auth/email-already-in-use') {
                      setError({
                        email:
                          'This email address is already registered to another account.'
                      });
                    }
                    if (error.code === 'auth/weak-password') {
                      setError({
                        password:
                          'Your password is too weak. It needs to be at least 6 characters.'
                      });
                    }
                    if (error.code === 'auth/invalid-email') {
                      setError({
                        email:
                          "Your email address doesn't look right. Please check it again."
                      });
                    }
                  }
                }}
              >
                Continue
              </Button>
            </Box>
            <Divider sx={{ marginTop: 3, marginBottom: 1 }} />
            <Typography variant="body2">Already have an account?</Typography>
            <Button
              onClick={() => navigate('/member/login', { replace: true })}
              size="small"
            >
              Login
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return content;
};

export default SignUp;
