import React from 'react';
import {
  Button,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import Lottie from 'react-lottie-player';
import backgroundImage from '../images/girl-snorkeler.jpg';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        height: '100vh'
      }}
    >
      <Container sx={{ p: 3 }}>
        <Paper
          sx={{
            background: theme.palette.action.disabled
          }}
        >
          <Grid container>
            <Grid item sm={6} sx={{ p: 3 }}>
              <Typography
                variant="h5"
                gutterBottom
                color={theme.palette.getContrastText(
                  theme.palette.action.disabled
                )}
              >
                Something went wrong 🙁
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                color={theme.palette.getContrastText(
                  theme.palette.action.disabled
                )}
              >
                It's probably best to head back to the start.
              </Typography>
              <Button
                variant="contained"
                onClick={() => navigate('/', { replace: true })}
                color="secondary"
                sx={{ mt: 3 }}
                size="large"
              >
                Back home
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Lottie
                loop
                animationData={require('../lottie/annoyed-fish.json')}
                play
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}
