import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Instagram, Facebook } from '@mui/icons-material';

export default function Footer() {
  const theme = useTheme();

  const HeaderText = ({ text }) => (
    <>
      <Typography variant="body1" sx={{ color: '#fff' }}>
        {text}
      </Typography>
      <Divider
        sx={{
          width: 1,
          marginTop: 1,
          marginBottom: 2,
          backgroundColor: '#fff'
        }}
      />
    </>
  );

  const PageLink = ({ link, text }) => (
    <Link
      to={link}
      style={{
        color: '#fff',
        textDecoration: 'none'
      }}
    >
      {text}
    </Link>
  );

  return (
    <Grid container sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" sx={{ padding: 1 }}>
          <Grid item xs={12} sm={4} sx={{ p: 3 }}>
            <HeaderText text="About" />
            <Typography variant="body2" sx={{ color: '#fff' }}>
              Synergy Diving is a team of people passionate about supporting
              freelance instructors in the diving community share their
              experiences with the world.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ p: 3 }}>
            <HeaderText text="Site links" />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <PageLink link="/mission" text="Our Mission" />
                </Typography>
                <Typography variant="body2">
                  <PageLink link="/thank-you" text="Special Thanks" />
                </Typography>
                <Typography variant="body2">
                  <PageLink link="/member" text="Member's login" />
                </Typography>
                <Typography variant="body2">
                  <PageLink link="/promos" text="Promotions" />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <a
                    href="https://synergydiving.wordpress.com"
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    Articles
                  </a>
                </Typography>
                <Typography variant="body2">
                  <PageLink link="/visual-media" text="Visual Media" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ p: 3 }}>
            <HeaderText text="Support" />
            <Typography variant="body2">
              <PageLink
                link="/terms-and-conditions"
                text="Terms & Conditions"
              />
            </Typography>
            <Typography variant="body2">
              <PageLink link="/privacy" text="Privacy" />
            </Typography>
            <Typography variant="body2">
              <PageLink link="/FAQ" text="FAQ" />
            </Typography>
            <Typography variant="body2">
              <PageLink link="/contact" text="Contact Us" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={7} sx={{ pl: 2 }}>
            <Typography variant="body2" sx={{ color: '#fff' }}>
              © {new Date().getFullYear()} Synergy Diving
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ textAlign: 'right', paddingRight: 2 }}>
            <IconButton
              href="https://www.instagram.com/synergydiving"
              size="large"
              sx={{ color: '#fff' }}
            >
              <Instagram fontSize="large" />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/synergydiving"
              size="large"
              sx={{ color: '#fff' }}
            >
              <Facebook fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
