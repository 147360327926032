import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';

export default function TermsConditions() {
  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 11,
          marginTop: 88,
          paddingBottom: 33
        }}
      >
        <Grid item xs={12} md={11} lg={8}>
          <Paper
            style={{
              padding: 22
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              TERMS AND CONDITIONS FOR SYNERGY DIVING
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Updated 13 June 2021
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Synergy Diving's Terms of Use
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to synergydiving.com (the "Site”). Synergy Diving provides
              its services to you subject to the following terms and conditions.
              ("Terms and Conditions" or "Agreement"). Please read the following
              terms and conditions carefully before using our Site. If you do
              not agree to these terms and conditions, please do not use our
              Site. By using or accessing the Site or services, you signify that
              you have read, understand and agree to be bound by these Terms of
              Use.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Electronic Communications
            </Typography>
            <Typography variant="body1" gutterBottom>
              When you visit synergydiving.com or send e-mails to us, you are
              communicating with us electronically. You consent to receive
              communications from us electronically. We will communicate with
              you by e-mail or by posting notices on this Site. You agree that
              all agreements, notices, disclosures and other communications that
              we provide to you electronically satisfy any legal requirement
              that such communications be in writing.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Intellectual Property
            </Typography>
            <Typography variant="body1" gutterBottom>
              All intellectual property on the Site (except for user generated
              content) is owned by Synergy Diving or its licensors, which
              includes materials protected by copyright, trademark, or patent
              laws. All trademarks, service marks and trade names (e.g., the
              Synergy Diving name and logo) are owned, registered and/or
              licensed by Synergy Diving. All content on the Site (except for
              user generated content), including but not limited to text,
              software, scripts, code, designs, graphics, photos, sounds, music,
              videos, applications, interactive features and all other content
              ("Content") is a collective work under New Zealand and other
              copyright laws and is the proprietary property ofSynergy Diving;
              All rights reserved.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Indemnification
            </Typography>
            <Typography variant="body1" gutterBottom>
              You agree to indemnify, defend, and hold harmless Synergy Diving,
              its affiliates, officers, directors, employees, agents, and
              suppliers from and against all claims, demands, expenses, damages,
              costs, injuries, death, including reasonable attorneys’ fees,
              resulting from your use of this Site, your conduct in connection
              with the Site, or your violation of these Terms of Use, any law or
              the rights of any third party.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Diving instructors, photographers, and boat owners and/or captains
              are liable for all claims, demands, expenses, damages, costs,
              injuries, and deaths associated with their activities listed on
              the Site.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Third Party Links
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving may link to third-party sites. Synergy Diving has
              no control over linked sites. These sites are for your convenience
              only and must be accessed at your own risk.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Service Descriptions
            </Typography>
            <Typography variant="body1" gutterBottom>
              Users are responsible for ensuring that their respective profile
              and the services that they offer are as accurate as possible.
              Synergy Diving does not warrant that service descriptions or other
              content of this Site is accurate, complete, reliable, current, or
              error-free.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Privacy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our Privacy Policy, which is incorporated into these Terms of Use
              by this reference, further describes the collection and use of
              information on this Site.
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              id="cancellation-refund-policy"
            >
              Cancellation and Refund Policy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Customers will receive a full refund (less fees) with 72 hours
              notice of cancellation prior to the start of an experience.
              Customers will also receive a full refund (less fees) in case of
              operator cancellation due to weather or other unforeseen
              circumstances. Customers will not be eligible for a refund on the
              basis of failing to meet an experience's prerequisites. Contact us
              by email to cancel or inquire about a cancellation.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Termination
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving reserves the right in its sole discretion to
              terminate your account and restrict your use of all or any part of
              the Site for any or no reason, without notice, and without
              liability to you or anyone else. Synergy Diving also reserves the
              right to block users from certain IP addresses or device numbers
              and prevent access to the Site. These Terms of Use remain in
              effect even after your account is terminated. The Terms of Use
              relating to Intellectual Property, Indemnification, Miscellaneous,
              and terms that by their nature may survive termination shall
              survive any termination.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Responsibility and Liability
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving, its agents, and contractors are not responsible or
              liable for any injury, loss, accident, damage, delay, omission,
              irregularity or nonperformance occurring in connection with
              services offered by users of the Site.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving cannot be held liable for and will not be
              responsible for loss or damage on personal items.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              The Copyright Act
            </Typography>
            <Typography variant="body1" gutterBottom>
              The Copyright Act of 1994 provides recourse for copyright owners
              who believe that material appearing on the Internet infringes
              their rights under New Zealand copyright law. If you believe in
              good faith that materials hosted by this Site infringe your
              copyright, you (or your agent) may send us a notice requesting
              that the material be removed, or access to it blocked. Please send
              notices to hello@synergydiving.com
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Insurance
            </Typography>
            <Typography variant="body1" gutterBottom>
              We strongly urge all our users to purchase appropriate insurance
              packages that cover accidents, medical, baggage, personal
              liability and effects and trip cancellation/interruption when a
              reservation is made. We strongly recommend our users use an
              insurer that covers any travel disruption due to technical
              malfunction/cancellation of planes and/or any other mode of
              transportation to the departure point of the booked trip,
              including travel disruptions due to natural disasters including
              but not limited to volcanic activities, earthquakes, flooding, or
              severe storms.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please be advised that if users choose not to carry insurance,
              Synergy Diving will not be held responsible for any financial
              disappointment caused by reasons beyond our control.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Medical and Health
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving is not certified to give you any medical advice. If
              you are unsure about any medical matter then please seek advice
              from a certified General Practitioner before attempting any diving
              activity.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Return Policy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving does not accept returns and cannot be held liable
              for any costs, losses, damages and/or defects associated with any
              product sold on the site. Synergy Diving is not responsible for
              any costs, losses, damages, and/or defects associated with the
              shipping of a product. All returns, losses, defects, and/or
              damages associated with a product, including shipping, is the sole
              responsibility of the member selling the item.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Content Management
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergy Diving reserves the right to amend any and all content,
              such as text or imagery, that is deemed offensive or
              inappropriate. This includes, but is not limited to, editing or
              deleting a member’s profile and/or experience.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
