import { useState, useEffect } from 'react';
import { Grid, Paper, TextField } from '@mui/material';
import shark from '../images/shark.jpg';

const AccessControl = ({ setAccessGranted }) => {
  const [accessPassword, setAccessPassword] = useState();

  useEffect(() => {
    if (accessPassword === process.env.REACT_APP_ACCESS_CONTROL) {
      setAccessGranted(true);
    }
  }, [accessPassword, setAccessGranted]);

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${shark})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh'
      }}
      justifyContent="center"
    >
      <Grid item xs={12} sm={5}>
        <Paper
          style={{
            marginLeft: 11,
            marginRight: 11,
            marginTop: 111,
            backgroundColor: `rgba(0,0,0,0.55)`
          }}
        >
          <TextField
            autoFocus
            label="Authentication required"
            variant="outlined"
            fullWidth
            value={accessPassword}
            onChange={evt => setAccessPassword(evt.target.value)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AccessControl;
