import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';

export default function Mission() {
  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 11,
          marginTop: 11,
          paddingBottom: 33
        }}
      >
        <Grid item md={8} lg={8}>
          <Paper
            style={{
              marginTop: 77,
              padding: 22
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              SYNERGY DIVING'S PRIVACY AND COOKIES POLICY
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Updated 17 May 2021
            </Typography>
            <Typography variant="body1" gutterBottom>
              This notice describes Synergy Diving&rsquo;s Privacy Policy. By
              visiting synergydiving.com, you consent to the practices described
              in this Privacy Policy. Synergy Diving may modify this Privacy
              Policy at any time. Your continued use of Synergy Diving&rsquo;s
              products and services and synergydiving.com constitutes your
              acceptance of this Privacy Policy and any updates. This Privacy
              Policy is incorporated into, and is subject to Synergy
              Diving&rsquo;s Terms of Use.
            </Typography>
            <Typography variant="body1" gutterBottom>
              For the purposes of European data protection law, the data
              controller of synerydiving.com is Synergy Diving Ltd. The data
              controller is responsible for determining why your data is
              collected and how it is processed.
            </Typography>
            <Typography variant="body1" gutterBottom>
              In this Policy, we cover:
              <br />
              Information We Collect
              <br />
              How We Collect Information
              <br />
              How We Use Your Information
              <br />
              Who We Share Information With
              <br />
              Do Not Track
              <br />
              Protection of Children&#39;s Privacy
              <br />
              Information for International Users
              <br />
              How Secure is My Personal Information?
              <br />
              Questions/Contact Us
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Information We Collect
            </Typography>
            <Typography variant="body1" gutterBottom>
              We collect information you give us, permit us to access or when
              you enter into a contract or sales transaction with us. Such
              information generally falls into the following categories:
            </Typography>
            <ul>
              <li>
                Contact information, such as your name, e-mail, physical address
                and telephone number;
              </li>
              <li>
                Account information, such as your username and password,
                birthdate, order navigate and preferences when using our
                products or services and information we need to honor warranties
                for products you have purchased;
              </li>
              <li>
                Demographic information, such as your gender and your date of
                birth;
              </li>
              <li>
                Payment information, such as your credit card number, expiration
                date, card verification number, and billing address if you make
                a purchase.
              </li>
              <li>
                Location information, including GPS information, activity and
                performance information;
              </li>
              <li>
                Social media information, including any profile information and
                contact lists (collected in limited circumstances); and
              </li>
              <li>
                Internet activity information, such as your IP address, browser
                type and language, operating system, cookie information
                (described below), web beacons (described below), pages visited
                on our site, referring and exit pages, and the dates and times
                of the visits.
              </li>
              <li>
                Information about your customer experience: This includes your
                responses to survey questions, reviews and other feedback you
                provide, and general information that customers provide about
                customers&rsquo; experiences. Our methods for collecting
                information about your experience may also collect information
                about your browser or device, but generally do not include
                identifying information such as your name and email address,
                unless you choose to provide it.
              </li>
            </ul>
            <Typography variant="body1" gutterBottom>
              We process your information on the basis that your information is
              required for us to provide the website, products and services to
              you under the relevant contract between us.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              How We Collect Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              We collect and obtain information:
            </Typography>
            <ul>
              <li>
                From you. We collect information that you provide to us when you
                sign up for an account, contact us, make a purchase, enter a
                sweepstakes or contest, or apply for a job with us.
              </li>
              <li>
                From your device, including through:
                <ul>
                  <li>
                    Cookies. We automatically collect information using
                    &quot;cookies.&quot; Cookies are small data files stored on
                    your hard drive by a web site. Among other things, cookies
                    help us improve our site and your experience. Using cookies
                    helps us see which areas and features are popular and helps
                    us count visits to our site. When you click
                    &quot;Accept&quot; on our consent request,&nbsp;you agree to
                    the placement of cookies on your browser by us, our
                    analytics and service providers, and marketing partners. If
                    you do not click &quot;Accept&quot;, then we will remember
                    your opt-out preference. You may change your preferences or
                    withdraw your consent at any time. Many web browsers are set
                    to accept cookies by default. If you prefer, you can usually
                    choose to set your browser to remove cookies and to reject
                    cookies from our site. If you set your browser not to accept
                    cookies or if you reject a cookie, it may affect certain
                    features or services of our site.
                  </li>
                </ul>
              </li>
              <li>
                Web Beacons. We collect information using web beacons. Web
                beacons, or &quot;gifs,&quot; are electronic images that are
                used on our site or in our e-mails. We use web beacons to
                deliver cookies, count visits, understand usage and campaign
                effectiveness and to tell if an email has been opened and acted
                upon. If you do not wish for us to track emails we send you,
                some e-mail services allow you to adjust your display to turn
                off HTML or images which may disable tracking, or you may
                unsubscribe from our marketing e-mails via contacting Synergy
                Diving or clicking &quot;unsubscribe&quot; in any marketing
                email.
              </li>
              <li>
                From other third parties. A number of our suppliers also set
                cookies, web beacons, pixels, tags, and scripts on your browser
                or device on our behalf when you visit our site in order to
                deliver the services they are providing, analyze trends and site
                performance, administer the site, track users&#39; movements and
                behavior around the site, measure advertising effectiveness, and
                prevent fraud. This means that when you visit our site you
                receive cookies from third party websites or domains. We receive
                reports based on the use of these technologies by these
                companies on an individual and aggregated basis. In addition, we
                may receive your information from third-party shopping services.
                We use that information only to fulfill orders.
              </li>
            </ul>
            <Typography variant="body1" gutterBottom>
              In the U.S., you can opt out of tracking on our website or others
              for online behavioral advertising by visiting the DAA&#39;s
              Consumer Choice page at http://www.aboutads.info/choices/ or the
              Network Advertising Initiative&#39;s page at
              http://www.networkadvertising.org/choices/. In Europe, you can
              make choices about whether to allow tracking when you first visit
              our site and are asked to accept trackers.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              How We Use Your Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              Except as set forth below in this section, we will keep all
              information collected from you, including information collected on
              snergydiving.com, confidential. We will use the collected
              information for the following legitimate purposes:
            </Typography>
            <ul>
              <li>
                To fulfill the services and provide the products you request
                from us. For this purpose, we typically collect contact
                information, account information, payment information, location
                information, and internet activity information.
              </li>
              <li>
                To enhance or customize your shopping experience with us. For
                this purpose, we typically use account information, demographic
                information, and internet activity information.
              </li>
              <li>
                To better understand how users access and use our site, both on
                an aggregated and individualized basis. For this purpose, we
                typically use all categories of personal information that we
                collect.
              </li>
              <li>
                To evaluate our site and our offerings to you as a visitor. For
                this purpose, we typically use internet activity information.
              </li>
              <li>
                To respond to user preferences. For this purpose, we typically
                use account information, contact information, and internet
                activity information.
              </li>
              <li>
                For research and analytical purposes. For this purpose, we
                typically use all categories of information that we collect.
              </li>
              <li>
                To send you marketing notices including promotions of our
                products and services. For this purpose, we typically use
                contact information, demographic information, and location
                information.
              </li>
            </ul>
            <Typography variant="h6" component="h2" gutterBottom>
              Who We Share Information With
            </Typography>
            <Typography variant="body1">
              We share your information as described below:
            </Typography>
            <ul>
              <li>
                Authorized service providers. We employ other companies to
                perform functions on our behalf. These other companies help us
                by fulfilling orders, delivering packages, sending postal mail
                and e-mail, removing repetitive information from customer lists,
                analyzing data, analyzing traffic on our websites, providing
                marketing assistance, providing surveys about our products and
                services, providing search results and links (including paid
                listings and links), processing credit card payments, assessing
                risk, automating decisions, and preventing fraud, and providing
                customer service. These companies have access to your
                information only as needed to perform their functions, but they
                are not permitted to use it for other purposes. We share account
                information and payment information with these service
                providers. We may also share information about your customer
                experience with service providers who help us work to improve
                your experience. We may provide location information and
                Internet activity information to service providers in certain
                circumstances.
              </li>
            </ul>
            <ul>
              <li>
                Social networks. We share Internet activity data with social
                networks when we advertise through their platforms.
              </li>
            </ul>
            <ul>
              <li>
                Data partners. In the United States, we participate in
                cooperative data sharing programs for marketing purposes, where
                participants provide customer contact information for U.S.
                customers and purchase history information to the program
                vendor. We provide account information and Internet activity
                information to these data partners. This sharing allows
                participants to identify and segment persons who may be
                interested in the participants&#39; products and services in
                order to target such persons with relevant marketing. In
                connection with our participation in these programs, we share
                U.S. customers&#39; names, physical addresses, aggregate
                transaction information, and purchase history information with
                other companies for their marketing purposes. (We do not
                generally share names and addresses with social networks.) If
                you are a U.S. customer and you do not wish to have your name,
                physical address, transaction or purchase history information
                disclosed or used through these cooperative programs, you may
                choose to not participate and can let us know by contacting us
                via the methods set forth on{' '}
                <a href="https://www.synergydiving.com/contact-us">
                  https://www.synergydiving.com/contact-us
                </a>
                .
              </li>
            </ul>
            <ul>
              <li>
                Law enforcement agencies, governmental authorities or
                regulators. We release account and other personal information
                when we believe release is appropriate to comply with the law,
                enforce or apply our conditions of use and other agreements, or
                protect the rights, property, or safety of burton.com, our
                users, or others. Typically, government authorities request
                account information, but we share any information required by a
                valid legal process.
              </li>
            </ul>
            <ul>
              <li>
                Credit reference and fraud prevention agencies. We exchange
                information with other companies and organizations for fraud
                protection and credit risk reduction. We share account
                information, including payment information and sometimes
                Internet activity information with these companies.
              </li>
            </ul>
            <ul>
              <li>
                Organizations in connection with a business transfer. We will
                release information if we are acquired by or merged with another
                company, or if substantially all of our assets are transferred
                to another company, or as part of a bankruptcy proceeding.
              </li>
            </ul>
            <Typography variant="body1" gutterBottom>
              Otherwise, we will keep the information we hold about you for as
              long as we believe is necessary to provide you the products,
              information and services you requested or as reasonably use for
              commercial purposes unless you tell us you want us to stop holding
              the information.{' '}
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Do Not Track
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our digital operations are conducted, in whole or in part, in New
              Zealand, which means that your information is transferred from
              your region and transferred to, processed and stored in New
              Zealand. The information is also transferred from New Zealand to
              your region for payment processing, warehousing and shipping
              purposes pursuant to our contractual agreements with such regional
              processors, warehouses and shippers. Regardless of where you live,
              you understand and agree that when you provide data to us for us
              to process in accordance with our legitimate interests or to
              fulfill an agreement with you (for example when you make a
              purchase) your data will be transferred, processed and stored in
              New Zealand, and you will allow Synergy Diving to use and collect
              your personal information in accordance with this Privacy Policy.
              When another company transfers your data to New Zealand, we agree
              to protect your data as appropriate.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Protection of Children's Privacy
            </Typography>
            <Typography variant="body1" gutterBottom>
              Synergydiving.com does not knowingly collect any personal
              information from children under 16. Also, if you are under 18, you
              may use synergydiving.com only with the involvement of a parent or
              guardian.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Your Rights
            </Typography>
            <Typography variant="body1">
              We collect and process your personal information on the basis that
              your personal information is required for us to provide the
              website, products and services to you under our contract with you
              or for the legitimate purposes set out in this Policy. You have
              choices related to how we use and share your personal information.
              In particular, you always have the right to request that we:
            </Typography>
            <ul>
              <li>
                provide you information about how we use your personal
                information;
              </li>
              <li>
                give you access to, and a copy of, your personal information
                that we store on our system;
              </li>
            </ul>
            <ul>
              <li>
                update, correct or delete your information held in our files;
              </li>
              <li>
                stop using, and require that third parties stop using, some or
                all of your personal information for certain purposes, even if
                you continue to use our site or services; and
              </li>
              <li>discontinue contacting you.</li>
            </ul>
            <Typography variant="body1" gutterBottom>
              If you would like us to change how we deal with your personal
              information, you can send us an email at hello@synergydiving.com.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              How Secure is My Personal Information?
            </Typography>
            <Typography variant="body1">
              We have implemented commercially reasonable precautions to protect
              the information we collect from loss, misuse, and unauthorized
              access, disclosure, alteration, and destruction, in accordance
              with industry practice and applicable laws. Please be aware that
              despite our best efforts, no data security measures can guarantee
              100% security. While we strive to protect information transmitted
              on or through our site, we cannot and do not guarantee the
              security of any information you transmit on or through the site
              and you do so at your own risk.
            </Typography>
            <Typography variant="body1" gutterBottom>
              You should take steps to protect against unauthorized access to
              your password, phone, and computer by, among other things, signing
              off after using a shared computer, choosing a robust password that
              nobody else knows or can easily guess, and keeping your log-in and
              password private. We are not responsible for any lost, stolen, or
              compromised passwords or for any activity on your account via
              unauthorized password activity, except where otherwise required by
              applicable law.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
              Questions/Contact Us
            </Typography>
            <Typography variant="body1">
              If you have questions about the guidelines described in this
              Policy, please email us directly at hello@synergydiving.com.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
