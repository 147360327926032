import React from 'react';
import { Typography, Grid, Paper, Box, Link } from '@mui/material';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import backgroundImage from '../images/freediving-with-fish.jpeg';
import { Link as RouterLink } from 'react-router-dom';

export default function FAQ() {
  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        sx={{
          p: 1,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          my: 3
        }}
      >
        <Grid item md={8} lg={8}>
          <Paper
            sx={{
              mt: 8,
              p: 4
            }}
          >
            <Typography variant="h4" gutterBottom>
              FAQ
            </Typography>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                What currency are the prices in?
              </Typography>
              <Typography variant="body1" gutterBottom>
                All prices on our site are currently in USD.
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                What is your cancellation and refund policy?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Participants will receive back the cost of the experience they
                booked if they cancel in writing{' '}
                <Link component={RouterLink} to="/contact">
                  via email
                </Link>{' '}
                more than 72 hours before the start of that experience.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Synergy Diving does include a platform fee at the time of
                booking which is non-refundable. So if an experience costs $200,
                the price at checkout will be $222 (including a non-refundable
                $22 platform fee in this case). If the participant cancels more
                than 72 hours before the start of the experience, they will
                receive back $200.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Some experiences include a non-refundable deposit as the
                discretion of the instructor. So if in the above example there
                was a $50 non-refundable deposit as decided by the instructor,
                and the participant cancelled more than 72 hours before the
                experience commenced, the participant would receive back $150.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Participants who cancel less than 72 hours prior to an
                experience commencing, will receive no refund.
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                I have a separate online presence I advertise on. Can I use
                Synergy Diving’s booking system there?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Absolutely. You can copy your experience’s URL and paste it as a
                hyperlink on your personal website. When a customer clicks that
                link it will take them to your experience on Synergy Diving
                where a customer can pay for that experience.
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                What are your fees for instructors listing experiences?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Creating an account as well as listing experiences and products
                is free. The fees charged to us for processing credit card
                payments, however, are passed onto the member.
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                Why am I being asked for my social security number during the
                sign up process?
              </Typography>
              <Typography variant="body1" gutterBottom>
                We are partnered with Stripe, a world leader in payment
                infrastructure for online businesses. If you signup for a US
                account, Stripe requires your social security number in order to
                verify your identity so that they can process payments. We do
                not collect or store your social security number nor any
                personal banking information.
              </Typography>
              <Typography variant="body1" gutterBottom>
                For more information please see the follow link:{' '}
                <Link href="https://support.stripe.com/questions/date-of-birth-and-social-security-number-(ssn)-requirement-for-us-stripe-accounts">
                  https://support.stripe.com/questions/date-of-birth-and-social-security-number-(ssn)-requirement-for-us-stripe-accounts
                </Link>
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                What’s the difference between an experience and a product?
              </Typography>
              <Typography variant="body1" gutterBottom>
                The experience section is designed to showcase diving courses,
                boat cruises, and underwater photography shoots, among other
                things.
              </Typography>
              <Typography variant="body1" gutterBottom>
                The product section is designed to allow members to list their
                photography prints, merchandise, and dive gear, among other
                things.
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" gutterBottom>
                How are my remaining space for my experiences kept up-to-date?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Everytime a booking is made on your experience, the total number
                of available spaces left are updated for you. The Synergy Diving
                platform will also mark your experience as fully booked and
                prevent new bookings if the total number of bookings equals the
                total number of spaces you have allocated to the experience.
              </Typography>
              <Typography variant="body1" gutterBottom>
                If you also accept bookings for your experience elsewhere, make
                sure to update the number of available spaces in your
                experience. Failure to do may result in your experience being
                overbooked. We can absolutely refund customers who booked your
                experience, but in this scenario a fee will be passed onto you
                to cover transaction fees.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
