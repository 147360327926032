import React from 'react';
import { useParams } from 'react-router-dom';
import Experience from '../components/checkout/Experience';
import Product from '../components/checkout/Product';
import SynergyDivingProduct from '../components/checkout/SynergyDivingProduct';

const Checkout = () => {
  const { purchaseType, purchaseId, variantId } = useParams();

  if (purchaseType === 'experience') {
    return <Experience experienceId={purchaseId} />;
  } else if (purchaseType === 'product') {
    return <Product productId={purchaseId} variantId={variantId} />;
  } else if (purchaseType === 'synergy-diving') {
    return (
      <SynergyDivingProduct productId={purchaseId} variantId={variantId} />
    );
  }
};

export default Checkout;
