import { getDatabase, ref, get } from 'firebase/database';
import { Typography } from '@mui/material';
import logo from '../images/synergy-diving-logo-with-text.png';

const experienceDuration = (startDate, endDate) => {
  const durationInDays = Math.round(
    endDate.endOf('day').diff(startDate.startOf('day'), 'days', true)
  );

  return `${durationInDays} ${durationInDays === 1 ? 'day' : 'days'}`;
};

const generateParagraphs = text =>
  text.split('\n').map((line, i) => (
    <Typography variant="body1" gutterBottom key={i}>
      {line}
    </Typography>
  ));

// let logo, feelGood;
let feelGood;

/*
Uses https://developers.google.com/maps/documentation/javascript/reference/geometry#spherical.computeDistanceBetween
  */
// const computeDistance = (from, to) =>
//   window.google.maps.geometry.spherical.computeDistanceBetween(
//     new window.google.maps.LatLng(from),
//     new window.google.maps.LatLng(to)
//   );

// // geocode
// const geocode = async placeId => {
//   const geocoder = new window.google.maps.Geocoder();
//   return new Promise((resolve, reject) => {
//     geocoder.geocode(
//       {
//         placeId
//       },
//       (results, status) => {
//         resolve(results[0]);
//       }
//     );
//   });
// };

// const loadLogo = async () => {
//   const imageUrl = (await get(ref(getDatabase(), 'media/logo'))).val();
//   logo = imageUrl;
// };

const loadFeelGood = async () => {
  const snap = await get(ref(getDatabase(), 'feelGood'));
  feelGood = snap.val();
};

const getLogo = () => logo;
const getFeelGood = () => feelGood[new Date().getMinutes() % feelGood.length];

const isProfileReady = ({
  firstName,
  lastName,
  bio,
  stripeId,
  featuredImages,
  profileImage,
  hideProfile
}) =>
  firstName &&
  lastName &&
  bio &&
  stripeId &&
  featuredImages > 0 &&
  profileImage &&
  !hideProfile;

const isIphone = () => /iPhone/g.test(navigator.userAgent);

export {
  experienceDuration,
  generateParagraphs,
  // computeDistance,
  getFeelGood,
  loadFeelGood,
  getLogo,
  // loadLogo,
  isProfileReady,
  isIphone
};
