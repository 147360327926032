// used in the dashboard to render a product, and be able to edit it
import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import ProductData from './ProductData';
import {
  doc,
  collection,
  getFirestore,
  Timestamp,
  deleteDoc,
  updateDoc,
  getDocs,
  addDoc
} from 'firebase/firestore';
import { deleteObject, ref, getStorage, uploadBytes } from 'firebase/storage';

export default function Product({
  title,
  imageUrl,
  description,
  productId,
  imageDimension,
  variants,
  uid
}) {
  const [confirm, setConfirm] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  return (
    <>
      <Card>
        <CardMedia image={imageUrl} title={title} style={{ height: 222 }} />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Variants available: {variants.length}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            aria-label="edit"
            onClick={() => setShowEditProduct(true)}
            size="large"
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => setConfirm(true)}
            size="large"
          >
            <Delete />
          </IconButton>
        </CardActions>
      </Card>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete the "{title}" product?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirm(false);
              deleteObject(
                ref(
                  getStorage(),
                  `products/${uid}/${productId}_${imageDimension}x${imageDimension}.jpeg`
                )
              );

              deleteDoc(doc(getFirestore(), 'products', productId));

              setSnackbar({
                open: true,
                message: 'Deleting product...',
                duration: 8800
              });
              return updateDoc(doc(getFirestore(), 'members', uid), {
                lastUpdated: Timestamp.now()
              });
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {showEditProduct && (
        <ProductData
          uid={uid}
          initialTitle={title}
          initialVariants={variants}
          initialDescription={description}
          initialProductImage={imageUrl}
          showProductDialog={showEditProduct}
          dialogTitle="Edit a product"
          onSave={async ({ title, description, productImage, variants }) => {
            await updateDoc(doc(getFirestore(), 'products', productId), {
              description,
              title,
              memberId: uid
            });

            // first clear the current variants
            const variantsSnapshot = await getDocs(
              collection(getFirestore(), 'products', productId, 'variants')
            );

            for (let variantSnapshot of variantsSnapshot.docs) {
              await deleteDoc(variantSnapshot.ref);
            }

            // then re-create the variants
            for (const variant of variants) {
              await addDoc(
                collection(getFirestore(), 'products', productId, 'variants'),
                {
                  ...variant
                }
              );
            }

            await uploadBytes(
              ref(getStorage(), `products/${uid}/${productId}.jpeg`),
              productImage
            );

            return await updateDoc(doc(getFirestore(), 'members', uid), {
              lastUpdated: Timestamp.now()
            });
          }}
          imageDimension={imageDimension}
          closeDialog={updated => {
            setShowEditProduct(false);
            if (updated) {
              setSnackbar({
                open: true,
                message: 'Updating product...',
                duration: 8800
              });
            }
          }}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
      />
    </>
  );
}
