import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  ImageList,
  ImageListItem,
  Button,
  Select,
  MenuItem,
  Box,
  Stack,
  Alert,
  Link
} from '@mui/material';
import { getDownloadURL, getStorage, ref, listAll } from 'firebase/storage';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Loader from '../components/Loader';

const Store = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState();
  useEffect(() => {
    const initProducts = {};

    const fetchProducts = async () => {
      const synergyDivingProducts = await getDocs(
        collection(getFirestore(), 'products/synergy-diving/merch')
      );

      for (let synergyDivingProduct of synergyDivingProducts.docs) {
        const variants = {};
        const productVariants = await getDocs(
          collection(
            getFirestore(),
            `products/synergy-diving/merch/${synergyDivingProduct.id}/variants`
          )
        );

        for (const variant of productVariants.docs) {
          variants[variant.id] = variant.data();
        }
        initProducts[synergyDivingProduct.id] = {
          images: [],
          ...synergyDivingProduct.data(),
          productId: synergyDivingProduct.id,
          variants
        };
        const productImages = await listAll(
          ref(
            getStorage(),
            `products/synergy-diving/${synergyDivingProduct.id}`
          )
        );
        for (let productImage of productImages.items) {
          const imageUrl = await getDownloadURL(productImage);
          initProducts[synergyDivingProduct.id].images.push(imageUrl);
        }
      }

      setProducts(initProducts);
    };

    fetchProducts();
  }, []);

  const StoreProduct = ({
    variants,
    images,
    title,
    description,
    productId,
    available
  }) => {
    const [selectedVariantId, setSelectedVariantId] = useState();

    return (
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} lg={6}>
          <ImageList cols={2}>
            {images.map(img => (
              <ImageListItem key={img}>
                <img
                  src={`${img}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
          {available === 0 ? (
            <Alert severity="info" sx={{ my: 3 }}>
              We are currently sold out.
            </Alert>
          ) : (
            <>
              <Typography variant="caption" gutterBottom>
                Only {available} left in stock.
              </Typography>
              <Stack
                sx={{ my: 3 }}
                direction="row"
                spacing={3}
                alignItems="center"
              >
                <Select
                  value={selectedVariantId}
                  variant="outlined"
                  onChange={event => setSelectedVariantId(event.target.value)}
                >
                  {Object.keys(variants)
                    .sort((variantId1, variantId2) =>
                      variants[variantId1].title > variants[variantId2].title
                        ? 1
                        : -1
                    )
                    .map(variantId => (
                      <MenuItem value={variantId} key={variantId}>
                        {variants[variantId].title}
                      </MenuItem>
                    ))}
                </Select>
                {selectedVariantId && (
                  <Stack>
                    <Typography variant="h6">
                      Cost: ${variants[selectedVariantId].price}
                    </Typography>
                    <Typography variant="body2">
                      (includes free shipping)
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Box mt={3}>
                <Button
                  disabled={!selectedVariantId}
                  variant="contained"
                  onClick={async () =>
                    navigate(
                      `/checkout/synergy-diving/${productId}/${selectedVariantId}`
                    )
                  }
                >
                  Buy now
                </Button>
              </Box>
              <Alert severity="info" sx={{ mt: 3 }}>
                We currently only ship to the USA, but if you live outside of
                the USA,{' '}
                <Link component={RouterLink} to="/contact">
                  get in touch
                </Link>{' '}
                and we'll see what we can do 😊
              </Alert>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  if (!products) {
    return <Loader status="loading official merch..." />;
  }

  return (
    <>
      <HeaderBar />
      <Grid container sx={{ mt: 8, p: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Official Synergy Diving Merch
          </Typography>
          <hr />
        </Grid>
      </Grid>
      {Object.keys(products).map(product => (
        <StoreProduct {...products[product]} key={product} />
      ))}
      <Footer />
    </>
  );
};

export default Store;
