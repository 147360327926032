import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import HeaderBar from '../components/HeaderBar';
import Footer from '../components/Footer';
import backgroundImage from '../images/freediving-with-fish.jpeg';

export default function ThankYou() {
  return (
    <>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 11,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginTop: 33
        }}
      >
        <Grid item md={8} lg={8}>
          <Paper
            style={{
              marginTop: 88,
              padding: 22,
              marginBottom: 33
            }}
          >
            <Typography variant="h4" gutterBottom>
              Special Thanks
            </Typography>
            <Typography variant="body1" gutterBottom>
              He aha te mea nui o te ao
              <br />
              He tangata, he tangata, he tangata
            </Typography>
            <Typography variant="body1" gutterBottom>
              What is the most important thing in the world?
              <br />
              It is the people, it is the people, it is the people.
            </Typography>
            <Typography variant="body1" gutterBottom>
              This Whakataukī (Maori proverb) not only reflects the way we feel
              about our dive community, but it also empowers us to celebrate our
              New Zealand identity and connection to the Pacific region.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We would like to personally thank our diving community members for
              all of your support, wisdom, and creative suggestions throughout
              our development journey. We would not have made it this far
              without you. Seriously, you guys are awesome! 🙏
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
