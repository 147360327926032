import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { VerifiedUser } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const MemberCard = ({ name, bio, uid, title }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState();
  const [verifications, setVerifications] = useState();

  useEffect(() => {
    const loadProfileImage = async () => {
      const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;
      let memberImage;
      try {
        memberImage = await getDownloadURL(
          ref(
            getStorage(),
            `members/${uid}/profile_${maxImageDimension}x${maxImageDimension}.jpeg`
          )
        );
        setImageUrl(memberImage);
      } catch (error) {
        console.log(error.code);
        memberImage = `https://picsum.photos/${maxImageDimension}`;
        setImageUrl(memberImage);
      }
    };

    const loadVerifications = async () => {
      const verificationsSnapshot = await getDocs(
        collection(getFirestore(), 'members', uid, 'verifications')
      );

      const verificationData = {};
      for (const verficationSnapshot of verificationsSnapshot.docs) {
        if (verficationSnapshot.data().verified) {
          verificationData[verficationSnapshot.id] = verficationSnapshot.data();
        }
      }

      setVerifications(verificationData);
    };

    loadProfileImage();
    loadVerifications();
  }, [uid]);

  const listItem = verificationId => (
    <ListItem key={verificationId} disableGutters>
      <ListItemIcon>
        <VerifiedUser sx={{ color: theme.palette.secondary.main }} />
      </ListItemIcon>
      <ListItemText primary={verifications[verificationId].assertion} />
    </ListItem>
  );

  return (
    <Grid item xs={12} md={4} sx={{ mb: 3 }}>
      <Card>
        <CardHeader subheader={title} />
        {imageUrl && (
          <CardMedia sx={{ height: 444 }} image={imageUrl} title={name} />
        )}
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {name}
          </Typography>
          {verifications && (
            <List>
              {Object.keys(verifications).map(verificationId =>
                listItem(verificationId)
              )}
            </List>
          )}
          <Typography variant="body2" color="textSecondary" component="p">
            {bio.substring(0, 333)}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => navigate(`/profiles/${uid}`)}
          >
            view profile
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default MemberCard;
