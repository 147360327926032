// get country codes from https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
import { httpsCallable, getFunctions } from 'firebase/functions';

const getSupportedStripeCountries = async () => {
  console.time('getting country list');
  const getSupportedCountries = httpsCallable(
    getFunctions(),
    'getSupportedCountries'
  );
  const countrySpecs = await getSupportedCountries();
  console.timeEnd('getting country list');
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const countries = {};
  for (const country of countrySpecs.data) {
    countries[country.id] = regionNames.of(country.id);
  }

  return countries;
};

export { getSupportedStripeCountries };
