import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  ImageListItem,
  Paper,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  Divider,
  Avatar,
  DialogContent,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Snackbar,
  useMediaQuery,
  ImageList
} from '@mui/material';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import { useTheme } from '@mui/material/styles';
import {
  VerifiedUser,
  Close,
  Edit,
  Share,
  Event,
  LocationOn
} from '@mui/icons-material';
import HeaderBar from '../components/HeaderBar';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import {
  onSnapshot,
  getFirestore,
  doc,
  getDocs,
  query,
  where,
  collection,
  Timestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, listAll } from 'firebase/storage';
import {
  generateParagraphs,
  experienceDuration,
  isProfileReady
} from '../modules/utils';

dayjs.extend(objectSupport);

export default function Profile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { profileId } = useParams();
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [member, setMember] = useState();
  const [memberImage, setMemberImage] = useState();
  const [experiences, setExperiences] = useState();
  const [reviews, setReviews] = useState();
  const [products, setProducts] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState();
  const [selectedVariantId, setSelectedVariantId] = useState('');
  const [verifications, setVerifications] = useState();
  const [featuredImages, setFeaturedImages] = useState();
  const [dialogInfo, setDialogInfo] = useState({ show: false });
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [showProductDialog, setShowProductDialog] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setStatus('Loading member profile...');
        onSnapshot(
          doc(getFirestore(), 'members', profileId),
          async memberRef => {
            setError(null);
            if (!memberRef.exists) {
              setError('This profile does not exist.');
            }
            // get member data
            const memberData = memberRef.data();

            if (!isProfileReady(memberData)) {
              setError(
                'This profile is incomplete. It is not quite ready for public viewing.'
              );
              return;
            }
            setMember(memberData);

            // get any reviews

            const reviewsSnapshot = await getDocs(
              collection(getFirestore(), 'members', profileId, 'reviews')
            );

            const currReviews = [];
            for (const review of reviewsSnapshot.docs) {
              currReviews.push({ ...review.data(), id: review.id });
            }
            setReviews(currReviews);

            setStatus('Checking verifications...');
            onSnapshot(
              collection(getFirestore(), 'members', profileId, 'verifications'),
              verificationsSnapshot => {
                const verificationData = {};
                for (const verficationSnapshot of verificationsSnapshot.docs) {
                  if (verficationSnapshot.data().verified) {
                    verificationData[verficationSnapshot.id] =
                      verficationSnapshot.data();
                  }
                }
                setVerifications(verificationData);
              }
            );

            const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

            setStatus('Loading member profile image...');
            // get member image
            let memberUrl;
            memberUrl = await getDownloadURL(
              ref(
                getStorage(),
                `members/${profileId}/profile_${maxImageDimension}x${maxImageDimension}.jpeg`
              )
            );

            setMemberImage(memberUrl);

            setStatus('Loading experiences...');
            // get experiences for this member
            const experiencesSnapshot = await getDocs(
              query(
                collection(getFirestore(), 'experiences'),
                where('memberId', '==', profileId),
                where(
                  'timestamps.startDate',
                  '>=',
                  Timestamp.fromDate(new Date())
                )
              )
            );

            const experiencesData = [];
            for (let experienceSnapshot of experiencesSnapshot.docs) {
              let image;
              try {
                image = await getDownloadURL(
                  ref(
                    getStorage(),
                    `experiences/${profileId}/${experienceSnapshot.id}_${maxImageDimension}x${maxImageDimension}.jpeg`
                  )
                );
              } catch (error) {
                image = 'https://picsum.photos/800';
              }

              experiencesData.push({
                ...experienceSnapshot.data(),
                image,
                id: experienceSnapshot.id
              });
            }
            setExperiences(experiencesData);

            setStatus('Loading products...');
            // get experiences for this member
            const productsSnapshot = await getDocs(
              query(
                collection(getFirestore(), 'products'),
                where('memberId', '==', profileId)
              )
            );
            const productsData = [];
            for (let productSnapshot of productsSnapshot.docs) {
              let image;
              try {
                image = await getDownloadURL(
                  ref(
                    getStorage(),
                    `products/${profileId}/${productSnapshot.id}_${maxImageDimension}x${maxImageDimension}.jpeg`
                  )
                );
              } catch (error) {
                image = 'https://picsum.photos/800';
              }

              const variantsSnapshot = await getDocs(
                collection(
                  getFirestore(),
                  'products',
                  productSnapshot.id,
                  'variants'
                )
              );
              const variants = {};
              for (let variantSnapshot of variantsSnapshot.docs) {
                variants[variantSnapshot.id] = variantSnapshot.data();
              }

              productsData.push({
                ...productSnapshot.data(),
                image,
                id: productSnapshot.id,
                variants
              });
            }
            setProducts(productsData);

            setStatus('Loading featured images...');
            // get featured images
            const results = await listAll(
              ref(getStorage(), `members/${profileId}/featured-images`)
            );

            const featuredImagesData = [];
            for (let itemRef of results.items) {
              const imageUrl = await getDownloadURL(itemRef);
              featuredImagesData.push(imageUrl);
            }
            setFeaturedImages(featuredImagesData);
          }
        );
      } catch (error) {
        console.log(error);
        setError(`Could not load profile for ${profileId}`);
      }
    };
    loadData();
  }, [profileId]);

  let dashboard;
  if (error) {
    dashboard = (
      <Grid container justifyContent="center">
        <Grid item style={{ marginTop: 55, maxWidth: 555 }}>
          <Lottie
            loop
            animationData={require('../lottie/space-panda-and-turtle.json')}
            play
          />
          <Typography style={{ padding: 11 }} variant="body1" align="center">
            {error}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (
    !member ||
    !memberImage ||
    !experiences ||
    !featuredImages ||
    !products ||
    !reviews
  ) {
    dashboard = <Loader status={status} />;
  } else {
    const profileAvatar = (
      <Avatar
        alt={member.firstName}
        src={memberImage}
        style={{ marginRight: 11, width: 88, height: 88 }}
        onClick={() =>
          setDialogInfo({
            content: (
              <DialogContent
                style={{
                  backgroundImage: `url(${memberImage})`,
                  height: '88vh',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  paddingTop: 88
                }}
              ></DialogContent>
            ),
            show: true
          })
        }
      />
    );

    dashboard = (
      <Fragment>
        <HeaderBar />
        <Grid
          container
          style={{ marginTop: 88, paddingLeft: 33, paddingRight: 33 }}
          justifyContent="center"
        >
          <Grid item xs={12} lg={10}>
            {getAuth().currentUser?.uid === profileId ? (
              <Button
                startIcon={<Edit />}
                style={{ margin: 11 }}
                variant="outlined"
                size="small"
                onClick={() => navigate('/member/dashboard')}
              >
                Edit profile
              </Button>
            ) : (
              ''
            )}
            <ImageList cellHeight={330} cols={xsScreen ? 2 : 4}>
              {featuredImages.map((featuredImage, i) => {
                let colsForTile = 1;
                if (featuredImages.length === 1) {
                  colsForTile = xsScreen ? 2 : 4;
                } else if (featuredImages.length === 2) {
                  colsForTile = xsScreen ? 1 : 2;
                } else if (featuredImages.length === 3) {
                  if (i + 1 === 1) {
                    colsForTile = 2;
                  }
                }
                return (
                  <ImageListItem
                    cols={colsForTile}
                    key={i}
                    onClick={() =>
                      setDialogInfo({
                        content: (
                          <DialogContent
                            style={{
                              backgroundImage: `url(${featuredImage})`,
                              height: '88vh',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              paddingTop: 88
                            }}
                          ></DialogContent>
                        ),
                        show: true
                      })
                    }
                  >
                    <img src={featuredImage} alt={i} />
                  </ImageListItem>
                );
              })}
            </ImageList>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 33,
                marginTop: 44
              }}
            >
              {profileAvatar}
              <div>
                <Typography variant="h5" gutterBottom>
                  {member.firstName} {member.lastName}
                </Typography>
                <Button
                  startIcon={<Share />}
                  style={{ margin: 11 }}
                  variant="outlined"
                  size="small"
                  onClick={async () => {
                    await navigator.clipboard.writeText(window.location.href);
                    setSnackbar({
                      open: true,
                      message: `${member.firstName}'s profile link copied to clipboard`,
                      duration: 3300
                    });
                  }}
                >
                  Share profile
                </Button>
              </div>
            </div>
            {Object.keys(verifications).length > 0 && (
              <>
                <Divider
                  style={{
                    marginTop: 33,
                    marginBottom: 33
                  }}
                />
                <Typography variant="h5" gutterBottom>
                  Verified
                </Typography>

                {Object.keys(verifications)
                  .sort((vId1, vId2) =>
                    verifications[vId1].assertion >
                    verifications[vId2].assertion
                      ? 1
                      : -1
                  )
                  .map(verificationId => (
                    <Typography
                      variant="body1"
                      key={verificationId}
                      style={{
                        display: 'flex'
                      }}
                      gutterBottom
                    >
                      <VerifiedUser
                        style={{
                          marginRight: 11,
                          color: theme.palette.primary.light
                        }}
                      />{' '}
                      {verifications[verificationId].assertion}
                    </Typography>
                  ))}
              </>
            )}
            {experiences.length > 0 && (
              <>
                <Divider
                  style={{
                    marginTop: 33,
                    marginBottom: 33
                  }}
                />
                <Typography variant="h5" gutterBottom>
                  Upcoming Experiences
                </Typography>
              </>
            )}
            <Grid container spacing={3}>
              {experiences.map(experience => (
                <Grid item xs={12} md={4} key={experience.id}>
                  <Card>
                    {experience.participants.enrolled ===
                    experience.participants.max ? (
                      <CardHeader title="Fully booked" />
                    ) : (
                      <CardHeader
                        subheader={`${
                          experience.participants.max -
                          experience.participants.enrolled
                        } ${
                          experience.participants.max -
                            experience.participants.enrolled ===
                          1
                            ? 'spot'
                            : 'spots'
                        } left`}
                      />
                    )}
                    <CardMedia
                      style={{
                        height: 220
                      }}
                      image={experience.image}
                      title={experience.title}
                    />
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {experience.title}
                      </Typography>
                      <div style={{ display: 'flex', marginBottom: 8 }}>
                        <Event style={{ marginRight: 8 }} fontSize="small" />
                        <Typography variant="body2">
                          {dayjs(experience.dates.start).format('DD MMM YYYY')}{' '}
                          (
                          {experienceDuration(
                            dayjs(experience.dates.start),
                            dayjs(experience.dates.end)
                          )}
                          )
                        </Typography>
                      </div>
                      {generateParagraphs(experience.description)}
                    </CardContent>
                    <CardActions>
                      <IconButton
                        aria-label="edit"
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            `${window.location.origin}/checkout/experience/${experience.id}`
                          );
                          setSnackbar({
                            open: true,
                            message: `Booking link for "${experience.title}" copied to clipboard`,
                            duration: 3300
                          });
                        }}
                      >
                        <Share />
                      </IconButton>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() =>
                          setDialogInfo({
                            content: (
                              <DialogContent
                                style={{
                                  backgroundImage: `url(${experience.image})`,
                                  height: '88vh',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  paddingTop: 88
                                }}
                              >
                                <Paper
                                  style={{
                                    padding: 33,
                                    margin: xsScreen ? 0 : 33
                                  }}
                                >
                                  <Typography variant="h5" gutterBottom>
                                    {experience.title}
                                  </Typography>
                                  <div
                                    style={{ display: 'flex', marginBottom: 8 }}
                                  >
                                    <Event
                                      style={{ marginRight: 8 }}
                                      fontSize="small"
                                    />
                                    <Typography variant="body2">
                                      {dayjs(experience.dates.start).format(
                                        'DD MMM YYYY'
                                      )}{' '}
                                      (
                                      {experienceDuration(
                                        dayjs(experience.dates.start),
                                        dayjs(experience.dates.end)
                                      )}
                                      )
                                    </Typography>
                                  </div>
                                  <div
                                    style={{ display: 'flex', marginBottom: 8 }}
                                  >
                                    <LocationOn
                                      style={{ marginRight: 8 }}
                                      fontSize="small"
                                    />
                                    <Typography variant="body2">
                                      {experience.place.address}
                                    </Typography>
                                  </div>
                                  <>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      style={{ fontStyle: 'italic' }}
                                    >
                                      Description
                                    </Typography>
                                    {generateParagraphs(experience.description)}
                                  </>
                                  {experience.prerequisites && (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        style={{ fontStyle: 'italic' }}
                                      >
                                        Prerequisites
                                      </Typography>
                                      {generateParagraphs(
                                        experience.prerequisites
                                      )}
                                    </>
                                  )}
                                  <Typography variant="subtitle2">
                                    Price: ${experience.price}
                                  </Typography>
                                  {experience.nonrefundableDeposit &&
                                    experience.nonrefundableDeposit
                                      .required && (
                                      <Typography
                                        variant="body2"
                                        sx={{ fontStyle: 'italic' }}
                                        gutterBottom
                                      >
                                        (This experience has a nonrefundable
                                        deposit of $
                                        {experience.nonrefundableDeposit.amount}
                                        )
                                      </Typography>
                                    )}
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                      experience.participants.enrolled ===
                                      experience.participants.max
                                    }
                                    style={{ marginTop: 22 }}
                                    onClick={async () =>
                                      navigate(
                                        `/checkout/experience/${experience.id}`
                                      )
                                    }
                                  >
                                    {experience.participants.enrolled ===
                                    experience.participants.max
                                      ? 'Sold out'
                                      : 'Book now'}
                                  </Button>
                                </Paper>
                              </DialogContent>
                            ),
                            show: true
                          })
                        }
                      >
                        Learn more
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {reviews.length > 0 && (
              <>
                <Divider
                  sx={{
                    my: 3
                  }}
                />
                <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                  Reviews
                </Typography>
                <Grid container spacing={3}>
                  {reviews.map(review => (
                    <Grid item xs={12} lg={10} key={review.id}>
                      <Typography sx={{ fontStyle: 'italic' }}>
                        "{review.comments}"
                      </Typography>
                      <Typography variant="caption">— {review.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            {products.length > 0 && (
              <>
                <Divider
                  style={{
                    marginTop: 33,
                    marginBottom: 33
                  }}
                />
                <Typography variant="h5" gutterBottom>
                  Products
                </Typography>
              </>
            )}
            <Grid container spacing={3}>
              {products.map(product => (
                <Grid item xs={12} md={4} key={product.id}>
                  <Card>
                    <CardMedia
                      style={{
                        height: 220
                      }}
                      image={product.image}
                      title={product.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        {product.title}
                      </Typography>
                      {product.description && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {product.description.substring(0, 111)}
                          ...
                        </Typography>
                      )}
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => setShowProductDialog(product.id)}
                      >
                        Learn more
                      </Button>
                    </CardActions>
                  </Card>
                  {showProductDialog === product.id && (
                    <Dialog
                      open={true}
                      onClose={() => {
                        setShowProductDialog(null);
                        setSelectedVariantId('');
                      }}
                      fullWidth={true}
                      fullScreen={xsScreen}
                      maxWidth="md"
                    >
                      <AppBar color="secondary" position="absolute">
                        <Toolbar>
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              setShowProductDialog(null);
                              setSelectedVariantId('');
                            }}
                            aria-label="close"
                          >
                            <Close />
                          </IconButton>
                          <Typography variant="h6">Products</Typography>
                        </Toolbar>
                      </AppBar>
                      <DialogContent>
                        <Grid
                          container
                          justifyContent="center"
                          style={{ marginTop: 55 }}
                        >
                          <Grid item xs={12} md={6} style={{ padding: 11 }}>
                            <Avatar
                              alt={product.title}
                              variant="rounded"
                              style={{
                                width: '100%',
                                height: '100%',
                                marginBottom: 11
                              }}
                              src={product.image}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} style={{ padding: 11 }}>
                            <Typography variant="h5" gutterBottom>
                              {product.title}
                            </Typography>
                            {generateParagraphs(product.description)}
                            <Select
                              value={selectedVariantId}
                              variant="outlined"
                              onChange={event =>
                                setSelectedVariantId(event.target.value)
                              }
                            >
                              {Object.keys(product.variants).map(variantId => (
                                <MenuItem value={variantId} key={variantId}>
                                  {product.variants[variantId].title}
                                </MenuItem>
                              ))}
                            </Select>
                            {selectedVariantId && (
                              <Typography
                                variant="h6"
                                style={{ marginTop: 33 }}
                              >
                                Cost: $
                                {product.variants[selectedVariantId].price}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={selectedVariantId === ''}
                          style={{ marginTop: 22 }}
                          onClick={async () =>
                            navigate(
                              `/checkout/product/${product.id}/${selectedVariantId}`
                            )
                          }
                        >
                          Purchase
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </Grid>
              ))}
            </Grid>
            <Divider
              style={{
                marginTop: 33,
                marginBottom: 33
              }}
            />
            <div style={{ display: 'flex', marginBottom: 33 }}>
              {profileAvatar}
              <div>
                <Typography variant="h5">Meet {member.firstName}</Typography>
                <Typography variant="caption">
                  Member on Synergy Diving
                </Typography>
              </div>
            </div>
            {member.bio && generateParagraphs(member.bio)}
            <Divider
              style={{
                marginTop: 33,
                marginBottom: 33
              }}
            />
          </Grid>
        </Grid>
        <Footer />
        <Dialog
          open={dialogInfo.show}
          onClose={() => setDialogInfo({ show: false })}
          fullWidth={true}
          fullScreen={xsScreen}
          maxWidth="md"
        >
          {dialogInfo.content}
          <DialogActions>
            <Button
              onClick={() => setDialogInfo({ show: false })}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  return (
    <>
      <HeaderBar />
      {dashboard}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        onClose={() => setSnackbar({ open: false, message: '' })}
        message={snackbar.message}
      />
    </>
  );
}
