import { initializeApp } from 'firebase/app';
// import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

// const initialiseFirebase = async () => {
let config;
// if (process.env.NODE_ENV === 'development') {
// config = {
//   apiKey: 'AIzaSyAbfj-cy8RrEHuuXVE0RF9h37XqYhzBD20',
//   authDomain: 'synergy-diving-development.firebaseapp.com',
//   projectId: 'synergy-diving-development',
//   storageBucket: 'synergy-diving-development.appspot.com',
//   messagingSenderId: '985723299702',
//   appId: '1:985723299702:web:02a6c463ce8644c616bc57',
//   measurementId: 'G-T4L5R8J75Y',
//   databaseURL: 'https://synergy-diving-development-default-rtdb.firebaseio.com/'
// };
// } else {
//   // const response = await fetch('/__/firebase/init.json');
// config = await response.json();
config = {
  apiKey: 'AIzaSyBELLsOC4wavpsM1gBtIpaEPoMDzV4PbF0',
  authDomain: 'synergy-diving.firebaseapp.com',
  projectId: 'synergy-diving',
  storageBucket: 'synergy-diving.appspot.com',
  messagingSenderId: '640608454485',
  appId: '1:640608454485:web:80133cb8e6ac05622e51dc',
  measurementId: 'G-SWB7N7WCXT',
  databaseURL: 'https://synergy-diving-default-rtdb.firebaseio.com/'
};
// }
console.log(config);
const app = initializeApp(config);
getAnalytics(app);

// connectFunctionsEmulator(getFunctions(), 'localhost', 5001);

if (process.env.REACT_APP_LOCAL_FUNCTIONS === 'true') {
  console.log('using local functions emulators...');
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
  // connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
}
// };

// export default initialiseFirebase;
